import { SettingPayload } from '@api/setting-service'
import dayjs from '@utils/dayjs'

const MONTH = 31

const getDateInterest = (dateCount: number, amount: number, interestRate: number) => {
  let rate = interestRate

  if (dateCount === 0) {
    return 0
  }

  if (dateCount >= 1 && dateCount <= 15) {
    rate = interestRate / 2
  } else if (dateCount >= 16 && dateCount <= 31) {
    rate = interestRate
  }

  return (rate / 100) * amount
}

export const getMonthInterest = (monthCount: number, amount: number, interestRate: number) => {
  return ((monthCount * interestRate) / 100) * amount
}

export const getPawnInterest = (
  {
    pawnDate,
    amount,
    targetDate = new Date().toISOString(),
  }: { pawnDate: string; amount: number; targetDate?: string },
  { interestRate }: SettingPayload,
  options?: { excludeDateCalculation?: boolean },
) => {
  const dateDiff = dayjs(dayjs(targetDate).set('hour', 0).toISOString()).diff(dayjs(pawnDate).set('hour', 0), 'day')

  const monthCount = options?.excludeDateCalculation ? Math.round(dateDiff / MONTH) : Math.floor(dateDiff / MONTH)
  const dateCount = dateDiff % MONTH

  return Number(
    parseFloat(
      String(
        getMonthInterest(monthCount, amount, interestRate) +
          (options?.excludeDateCalculation ? 0 : getDateInterest(dateCount, amount, interestRate)),
      ),
    ).toFixed(2),
  )
}
