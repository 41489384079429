import styled from '@emotion/styled'
import Card from '@material-ui/core/Card'

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled(Card)`
  min-width: 610px;
  padding: ${({ theme }) => theme.spacing(5, 13)};
`
