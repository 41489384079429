import { Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import * as React from 'react'
import { CheckWrapper, TitleWrapper } from './SummaryHeader.styled'

const SummaryHeader: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <React.Fragment>
      <CheckWrapper>
        <CheckCircleIcon style={{ fontSize: 80 }} />
      </CheckWrapper>
      <TitleWrapper>
        <Typography variant="h5">{title}</Typography>
      </TitleWrapper>
    </React.Fragment>
  )
}

export default SummaryHeader
