import { Container } from '@components/styled/Container'
import ItemForm from '@pages/Pawn/components/ItemForm/ItemForm'
import PIForm from '@pages/Pawn/components/PIForm'
import Summary from '@pages/Pawn/components/Summary'
import queryString from 'query-string'
import React from 'react'
import { useLocation } from 'react-router'
import { Wrapper } from './Pawn.styled'

type PawnData = {
  idCard: string
  firstName: string
  lastName: string
  phone: string
}

enum FormStep {
  PI_FORM = 'pi-form',
  ITEM_FORM = 'item-form',
  SUMMARY = 'summary',
}

const Pawn = () => {
  const location = useLocation()
  const params = queryString.parse(location.search)
  const [formStep, setFormStep] = React.useState<FormStep>(params.id ? FormStep.SUMMARY : FormStep.PI_FORM)
  const [values, setValues] = React.useState<PawnData>({
    idCard: '',
    firstName: '',
    lastName: '',
    phone: '',
  })

  const handlePIFormSubmit = (formValues) => {
    setValues({ ...values, ...formValues })
    setFormStep(FormStep.ITEM_FORM)
  }

  return (
    <Container>
      <Wrapper>
        {formStep === FormStep.PI_FORM && (
          <PIForm
            initialValues={{
              idCard: values.idCard,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
            }}
            onSubmit={handlePIFormSubmit}
          />
        )}
        {formStep === FormStep.ITEM_FORM && (
          <ItemForm
            values={values}
            onSubmit={() => setFormStep(FormStep.SUMMARY)}
            onBack={() => setFormStep(FormStep.PI_FORM)}
          />
        )}
        {formStep === FormStep.SUMMARY && <Summary />}
      </Wrapper>
    </Container>
  )
}

export default Pawn
