import { Box, CircularProgress, Typography } from '@material-ui/core'
import * as React from 'react'
import { Wrapper } from './Loader.styled'

export interface ILoaderProps {
  className?: HTMLElement['className']
}

const Loader: React.FC<ILoaderProps> = ({ className, children = 'กำลังโหลด...' }) => (
  <Wrapper className={className}>
    <CircularProgress />
    {children && (
      <Box mt={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </Wrapper>
)

export default Loader
