import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { FetchPawnResponse, pawnService, UpdatePawnRequest } from '@api/pawn-service'
import { SettingPayload, settingService } from '@api/setting-service'
import { transactionService, TransactionType } from '@api/transaction-service'
import { userService } from '@api/user-service'
import { queryClient } from '@app/query-client'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { useConfirm } from '@components/Modal/ConfirmModal'
import { useNotification } from '@components/notification'
import { Container } from '@components/styled/Container'
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { usePrintPawn } from '@shared/hooks/usePrintPawn'
import { useRouter } from '@shared/hooks/useRouter'
import dayjs from '@utils/dayjs'
import { atLeast, formatDate, formatNumber } from '@utils/format'
import { getPawnInterest } from '@utils/pawn'
import * as React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router'
import { GridDetail, IconWrapper, TitleWrapper, Wrapper } from './PawnDetail.styled'

const PawnDetail = () => {
  const { confirm } = useConfirm()
  const { pushDefaultError } = useNotification()
  const { history } = useRouter()
  const params = useParams<{ id: string }>()
  const { data: pawn, error, isLoading, refetch } = useQuery<FetchPawnResponse>(
    `fetchPawn-${params.id}`,
    () => pawnService.fetchPawn({ id: params?.id as string }),
    {
      staleTime: 0,
    },
  )
  const { mutate: updatePawn, isLoading: isPawnUpdating } = useMutation(
    (payload: UpdatePawnRequest) => pawnService.updatePawn(payload),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries()
        await refetch()
      },
    },
  )
  const { mutate: deletePawn, isLoading: isPawnDeleting } = useMutation(() => pawnService.deletePawn({ id: params.id }))
  const { refetch: handleTakeout, isLoading: isSettingLoading } = useQuery<SettingPayload>(
    'fetchSetting',
    () => settingService.fetchSetting(),
    {
      onSuccess: async (values) => {
        if (values && pawn) {
          const interest = atLeast(
            getPawnInterest({ pawnDate: pawn.updatedDate, amount: pawn.gold.priceAmount }, values),
          )
          const dateDiff = atLeast(
            dayjs(dayjs().set('hour', 0).toISOString()).diff(dayjs(pawn.updatedDate).set('hour', 0), 'day'),
          )
          const point = atLeast(Number(parseFloat(String((interest * values.rateConverter) / 100)).toFixed(2)))
          const user = await userService.addPoint({ id: pawn.user.id, point })

          queryClient.setQueryData('pawn-takeout', () => ({
            interest,
            point,
            day: dateDiff,
            user,
          }))

          await deletePawn()
          transactionService.createTransaction({
            info: pawn,
            type: TransactionType.TAKE_OUT,
            change: {
              point,
              interest,
              price: -pawn.gold.priceAmount,
              weight: -pawn.gold.goldWeight,
            },
          })

          history.push(`/pawn-takeout/${params.id}`)
        } else {
          pushDefaultError()
        }
      },
      enabled: false,
    },
  )
  const { print, isLoading: isPrinting } = usePrintPawn(params?.id)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleExtend = () => {
    history.push(`/pawn-extend/${params?.id}`)
  }

  const handleAdjust = () => {
    history.push(`/pawn-adjust/${params?.id}`)
  }

  const handleNotiMissing = async () => {
    // deleted field = แจ้งใบหาย
    updatePawn({ id: params.id, deleted: true })
  }

  const handleRemove = async () => {
    if (pawn) {
      await deletePawn()
      transactionService.createTransaction({
        info: pawn,
        type: TransactionType.REMOVE,
        change: {
          point: 0,
          interest: 0,
          price: -pawn.gold.priceAmount,
          weight: -pawn.gold.goldWeight,
        },
      })
      history.push(`/pawn-remove/${params?.id}`)
    }
  }

  if (isLoading) {
    return (
      <Container>
        <Wrapper>
          <Loader />
        </Wrapper>
      </Container>
    )
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <Typography variant="h4">เลขที่ใบจำนำ: {pawn?.id}</Typography>
          {pawn?.deleted && (
            <Box ml={2}>
              <Typography variant="h4" color="primary">
                (ใบหาย)
              </Typography>
            </Box>
          )}
          <IconWrapper onClick={handleClick} aria-haspopup="true">
            <MoreVertIcon fontSize="medium" style={{ color: '#333333' }} />
          </IconWrapper>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Paper>
              <List>
                <ListItem button onClick={() => print()}>
                  {isPrinting && (
                    <Box mr={2}>
                      <CircularProgress size={20} />
                    </Box>
                  )}
                  <ListItemText primary="พิมพ์ใบจำนำ" />
                </ListItem>
                <ListItem button onClick={handleExtend}>
                  <ListItemText primary="ต่อดอก" />
                </ListItem>
                <ListItem button onClick={handleAdjust}>
                  <ListItemText primary="เพิ่ม/ลดยอด" />
                </ListItem>
                <ListItem button onClick={() => confirm(() => handleTakeout())}>
                  {(isPawnDeleting || isSettingLoading) && (
                    <Box mr={2}>
                      <CircularProgress size={20} />
                    </Box>
                  )}
                  <ListItemText primary="ลูกค้าไถ่คืน" />
                </ListItem>
                {!pawn?.deleted && (
                  <ListItem button onClick={() => confirm(handleNotiMissing)}>
                    {isPawnUpdating && (
                      <Box mr={2}>
                        <CircularProgress size={20} />
                      </Box>
                    )}
                    <ListItemText primary="แจ้งใบหาย" />
                  </ListItem>
                )}
                <ListItem button onClick={() => confirm(handleRemove)}>
                  <ListItemText primary="คัดของออก" />
                </ListItem>
              </List>
            </Paper>
          </Popover>
        </TitleWrapper>
        <Box pl={3}>
          <GridDetail>
            <Typography>วันเวลาที่ทำรายการ:</Typography>
            <Box ml={2}>
              <Typography>{formatDate(pawn?.updatedDate, 'D MMM BBBB HH:mm')}</Typography>
            </Box>
          </GridDetail>
          <Box mt={3}>
            <span>
              <Typography variant="h5">
                ข้อมูลผู้จำนำ
                <Box component="span" ml={1}>
                  <IconButton onClick={() => window.open(`/user/${pawn?.user?.id}`)}>
                    <OpenInNewIcon />
                  </IconButton>
                </Box>
              </Typography>
            </span>
            <Box mt={2} pl={2}>
              <GridDetail>
                <Typography>ชื่อ:</Typography>
                <Typography>{pawn?.person.firstName}</Typography>
                <Typography>นามสกุล:</Typography>
                <Typography>{pawn?.person.lastName}</Typography>
                <Typography>รหัสบัตรประชาชน/หนังสือเดินทาง:</Typography>
                <Typography>{pawn?.person.idCard || '-'}</Typography>
                <Typography>เบอร์โทร:</Typography>
                <Typography>{pawn?.person.phone || '-'}</Typography>
              </GridDetail>
            </Box>
          </Box>
          <Box mt={3}>
            <Typography variant="h5">ข้อมูลของ</Typography>
            <Box mt={2} pl={2}>
              <GridDetail>
                <Typography>ประเภททอง:</Typography>
                <Typography>{pawn?.gold.goldTypes.join(', ')}</Typography>
                <Typography>น้ำหนักทอง:</Typography>
                <Typography>{pawn?.gold.goldWeight} กรัม</Typography>
                <Typography>จำนวนเงิน:</Typography>
                <Typography>{formatNumber(pawn?.gold.priceAmount)} บาท</Typography>
                <Typography>ข้อมูลเพิ่มเติม:</Typography>
                <Typography>{pawn?.gold.additionalInfo?.join(', ') || '-'}</Typography>
              </GridDetail>
            </Box>

            <Box mt={3}>
              <Typography variant="h5">ครบกำหนด</Typography>
              <Box mt={2} pl={2}>
                <GridDetail>
                  <Typography>ระยะเวลาครบกำหนด:</Typography>
                  <Typography>{pawn?.targetMonth} เดือน</Typography>
                  <Typography>วันที่ครบกำหนด:</Typography>
                  <Typography>{dayjs(pawn?.targetDate).format('D MMM BBBB')}</Typography>
                </GridDetail>
              </Box>
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </Container>
  )
}

export default PawnDetail
