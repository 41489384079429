import { User, userService } from '@api/user-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import Loader from '@components/Loader'
import { Container } from '@components/styled/Container'
import { Clickable } from '@components/styled/UI'
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import * as React from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Wrapper } from './SearchUser.styled'

const headCells = [
  { id: 'firstName', label: 'ชื่อ' },
  { id: 'lastName', label: 'นามสกุล' },
  { id: 'idCard', label: 'รหัสบัตรประชาชน/หนังสือเดินทาง' },
  { id: 'phone', label: 'หมายเลขโทรศัพท์' },
  { id: 'amount', label: 'เงินออม (บาท)' },
  { id: 'action', label: '' },
]

const SearchUser = () => {
  const [query, setQuery] = React.useState('')
  const searchKeyword = queryClient.getQueryData<string>('searchUserKeyWord')
  const { data, isLoading, refetch } = useQuery<User[]>(
    `fetchUsers-${searchKeyword}`,
    () => userService.fetchUsers(searchKeyword),
    {
      enabled: !!searchKeyword,
    },
  )
  const [isWaitingFromIdCard, setIsWaitingFormIdCard] = React.useState(false)
  useQuery<User[]>(`fetchUserSession`, () => userService.getSessionUserIdentity(), {
    enabled: isWaitingFromIdCard,
    onSuccess: (values: any) => {
      const query = `${values.idCard}`
      setQuery(query)
      setIsWaitingFormIdCard(false)
      queryClient.setQueryData('searchUserKeyWord', query)
      forceRerender({})
      userService.deleteSessionUserIdentity()
    },
    refetchInterval: 3000,
  })

  const [, forceRerender] = React.useState({})
  const [showAllUsers, setShowAllUsers] = React.useState(false)

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    queryClient.setQueryData('searchUserKeyWord', query)
    forceRerender({})
  }

  const handleReadFromIdCard = () => {
    setIsWaitingFormIdCard(true)
    userService.deleteSessionUserIdentity()
  }

  if (isWaitingFromIdCard) {
    return (
      <Container>
        <Wrapper>
          <Box display="flex" flexDirection="column">
            <Loader />
            <Box mt={2} mx="auto">
              <Button onClick={() => setIsWaitingFormIdCard(false)}>ยกเลิก</Button>
            </Box>
          </Box>
        </Wrapper>
      </Container>
    )
  }

  const tableHead = (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell size="small" key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const tableBody = (
    <TableBody>
      {data && data?.length > 0 ? (
        data?.map((user) => {
          const { id, firstName, lastName, idCard, phone, amount } = user
          return (
            <TableRow key={`unit-${id}`}>
              <TableCell size="small">{firstName}</TableCell>
              <TableCell size="small">{lastName}</TableCell>
              <TableCell size="small">{idCard || '-'}</TableCell>
              <TableCell size="small">{phone || '-'}</TableCell>
              <TableCell size="small">{amount || '-'}</TableCell>
              <TableCell size="small" align="left">
                <Link to={`/user/${id}`}>
                  <Typography color="primary">ดูข้อมูล</Typography>
                </Link>
              </TableCell>
            </TableRow>
          )
        })
      ) : (
        <TableRow>
          <TableCell size="small" colSpan={10}>
            ไม่พบข้อมูลลูกค้า
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )

  return (
    <Container>
      <Wrapper>
        <Box display="flex" justifyContent="space-between" alignItems="end" style={{ width: '100%' }}>
          <Typography variant="h4">ค้นหาข้อมูล</Typography>
          <Box display="flex" alignItems="flex-end">
            <Clickable onClick={handleReadFromIdCard}>
              <Typography color="primary">อ่านจากบัตรประชาชน</Typography>
            </Clickable>
            <Box
              ml={2}
              onClick={() => {
                setShowAllUsers(true)
                queryClient.setQueryData('searchUserKeyWord', undefined)
                refetch()
                forceRerender({})
              }}
            >
              <Button variant="outlined">ดูข้อมูลทั้งหมด</Button>
            </Box>
          </Box>
        </Box>
        <form onSubmit={handleSearch}>
          <Box mt={3}>
            <TextField
              variant="outlined"
              placeholder="ค้นหาชื่อ, นามสกุล, รหัสบัตรประชาชน/หนังสือเดินทาง"
              fullWidth
              value={query}
              onChange={(event) => setQuery(event.target.value)}
            />
          </Box>
          <Box mt={3}>
            <Button type="submit">ค้นหา</Button>
          </Box>
        </form>
      </Wrapper>
      <Box mt={2}>
        {(searchKeyword || showAllUsers) && (
          <Paper>
            {isLoading && (
              <Box p={3}>
                <Loader />
              </Box>
            )}
            {!isLoading && (
              <Box p={4}>
                <Typography variant="h5">
                  แสดง {data?.length} {!showAllUsers ? `ลูกค้าสำหรับ ${`"${searchKeyword}"`}` : 'ลูกค้า'}
                </Typography>
                <Box mt={2}>
                  <Table>
                    {tableHead}
                    {tableBody}
                  </Table>
                </Box>
              </Box>
            )}
          </Paper>
        )}
      </Box>
    </Container>
  )
}

export default SearchUser
