import { FetchPawnResponse, pawnService } from '@api/pawn-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import { GridContainer } from '@components/container'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { CommonCard } from '@components/styled/CommonCard'
import { Container } from '@components/styled/Container'
import SummaryHeader from '@components/SummaryHeader'
import { Box, Typography } from '@material-ui/core'
import { useRouter } from '@shared/hooks/useRouter'
import { formatNumber } from '@utils/format'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import * as React from 'react'

const PawnRemove = () => {
  const { query } = useRouter()
  const { data: pawn, error, isLoading, refetch } = useQuery<FetchPawnResponse>(`fetchPawn-${query.id}`, () =>
    pawnService.fetchPawn({ id: query?.id as string }),
  )

  React.useEffect(() => {
    return () => {
      queryClient.invalidateQueries()
    }
  }, [])

  if (isLoading) {
    return (
      <Container>
        <CommonCard>
          <Loader />
        </CommonCard>
      </Container>
    )
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Container>
      <CommonCard>
        <SummaryHeader title="คัดของออก" />
        <GridContainer columns={2}>
          <Typography>เลขที่ใบจำนำ: </Typography>
          <Typography>{pawn?.id}</Typography>
        </GridContainer>
        <Box my={3}>
          <Typography variant="h5">ข้อมูลของ</Typography>
        </Box>
        <GridContainer columns={2}>
          <Typography>ประเภททอง:</Typography>
          <Typography>{pawn?.gold.goldTypes.join(', ')}</Typography>
          <Typography>น้ำหนักทอง:</Typography>
          <Typography>{pawn?.gold.goldWeight} กรัม</Typography>
          <Typography>จำนวนเงิน:</Typography>
          <Typography>{formatNumber(pawn?.gold.priceAmount)} บาท</Typography>
          <Typography>ข้อมูลเพิ่มเติม:</Typography>
          <Typography>{pawn?.gold.additionalInfo?.join(', ') || '-'}</Typography>
        </GridContainer>
        <Box mt={4}>
          <Link to="/">
            <Button variant="outlined">หน้าแรก</Button>
          </Link>
        </Box>
      </CommonCard>
    </Container>
  )
}

export default PawnRemove
