import { FetchPawnResponse, pawnService } from '@api/pawn-service'
import { SettingPayload, settingService } from '@api/setting-service'
import { transactionService, TransactionType } from '@api/transaction-service'
import { userService } from '@api/user-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { useConfirm } from '@components/Modal/ConfirmModal'
import { useNotification } from '@components/notification'
import Select from '@components/Select'
import { CommonCard } from '@components/styled/CommonCard'
import { Container } from '@components/styled/Container'
import { Box, Typography } from '@material-ui/core'
import { useRouter } from '@shared/hooks/useRouter'
import dayjs from '@utils/dayjs'
import { getMonthInterest } from '@utils/pawn'
import * as React from 'react'
import { useMutation, useQuery } from 'react-query'
import { GridContainer } from './PawnExtend.styled'
import { Link } from 'react-router-dom'

const PawnExtend = () => {
  const { confirm } = useConfirm()
  const { pushDefaultError } = useNotification()
  const { query, history } = useRouter()
  const { data: setting, isLoading: isSettingLoading } = useQuery<SettingPayload>('fetchSetting', () =>
    settingService.fetchSetting(),
  )
  const { data: pawn, error, isLoading, refetch } = useQuery<FetchPawnResponse>(`fetchPawn-${query.id}`, () =>
    pawnService.fetchPawn({ id: query?.id as string }),
  )
  const { mutate: updatePawn, isLoading: isUpdating } = useMutation(pawnService.updatePawn, {
    mutationKey: 'updatePawn',
    onSuccess: async () => {
      if (setting && pawn) {
        const interest = getMonthInterest(targetInterestMonth, pawn.gold.priceAmount, setting.interestRate)
        const point = Number(parseFloat(String((interest * setting.rateConverter) / 100)).toFixed(2))
        const user = await userService.addPoint({ id: pawn.user.id, point })

        queryClient.setQueryData('pawn-extend-summary', () => ({
          interest,
          point,
          month: targetInterestMonth,
          user,
        }))

        await queryClient.invalidateQueries()

        await transactionService.createTransaction({
          info: pawn,
          type: TransactionType.EXTEND,
          change: {
            point,
            interest,
            price: 0,
            weight: 0,
          },
        })

        history.push(`/pawn-extend/${query.id}/summary`)
      } else {
        pushDefaultError()
      }
    },
    onError: () => {
      pushDefaultError()
    },
  })
  const [targetMonth, setTargetMonth] = React.useState(2)
  const [targetInterestMonth, setTargetInterestMonth] = React.useState(2)

  const handleExtend = () => {
    confirm(() =>
      updatePawn({
        id: query.id,
        targetMonth: targetMonth,
        targetDate: dayjs(pawn?.updatedDate)
          .add(targetMonth + targetInterestMonth, 'month')
          .toISOString(),
        updatedDate: dayjs(pawn?.updatedDate).add(targetInterestMonth, 'month').toISOString(),
      }),
    )
  }

  if (isLoading || isSettingLoading) {
    return (
      <Container>
        <CommonCard>
          <Loader />
        </CommonCard>
      </Container>
    )
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Container>
      <CommonCard>
        <Typography variant="h5">ต่อดอก</Typography>
        <Box mt={3}>
          <GridContainer>
            <Typography>เลขที่ใบจำนำ:</Typography>
            <Typography>{pawn?.id}</Typography>
          </GridContainer>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">ข้อมูลต่อดอก</Typography>
          <Box mt={3}>
            <Select
              label="ระยะเวลาครบกำหนด"
              value={targetMonth}
              options={Array.from({ length: 12 }).map((_, index) => ({
                label: `${index + 1} เดือน`,
                value: index + 1,
              }))}
              onChange={(value) => setTargetMonth(value)}
              fullWidth
            />
          </Box>
          <Box mt={3}>
            <GridContainer>
              <Typography>วันครบกำหนด:</Typography>
              <Typography>
                {dayjs(pawn?.updatedDate)
                  .add(targetInterestMonth + targetMonth, 'month')
                  .format('D MMM BBBB')}
              </Typography>
            </GridContainer>
          </Box>
          <Box mt={3}>
            <Typography variant="h5">การคิดดอกเบี้ย</Typography>
            <Box mt={3}>
              {pawn && (
                <Select
                  label="จำนวนเดือน"
                  value={targetInterestMonth}
                  options={Array.from({ length: 12 }).map((_, index) => ({
                    label: `${index + 1} เดือน`,
                    value: index + 1,
                  }))}
                  onChange={(value) => setTargetInterestMonth(value)}
                  fullWidth
                />
              )}
            </Box>
          </Box>

          <Box mt={4} display="flex">
            <Button onClick={handleExtend} isLoading={isUpdating}>
              ต่อดอก
            </Button>
            <Box ml={2}>
              <Link to={`/pawn/${query.id}`}>
                <Button variant="outlined">ยกเลิก</Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </CommonCard>
    </Container>
  )
}

export default PawnExtend
