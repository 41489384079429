import styled from '@emotion/styled'
import { Paper } from '@material-ui/core'

export const Wrapper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(5, 12)};
  margin: 0 auto;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  color: #ccc;
  cursor: pointer;
`

export const GridDetail = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)}px;
`
