import Button from '@components/Button'
import Loader from '@components/Loader'
import { Container } from '@components/styled/Container'
import { Box, Typography } from '@material-ui/core'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import * as React from 'react'
import { HeaderWrapper, HideContentWrapper } from './Dashboard.styled'
import Summary from '@pages/Dashboard/Summary'
import PawnsTable from '@pages/Dashboard/PawnsTable'

const Dashboard = () => {
  const [showContent, setShowContent] = React.useState(false)

  return (
    <Container>
      <HeaderWrapper>
        <Typography variant="h3">ภาพรวม</Typography>
        {!showContent && (
          <Button variant="outlined" onClick={() => setShowContent(true)}>
            แสดงข้อมูล
          </Button>
        )}
        {showContent && (
          <Button variant="outlined" onClick={() => setShowContent(false)}>
            ซ่อนข้อมูล
          </Button>
        )}
      </HeaderWrapper>
      {!showContent && (
        <HideContentWrapper>
          <VisibilityOffIcon style={{ fontSize: 80 }} />
          <Box mt={2}>
            <Typography variant="h6">ข้อมูลถูกซ่อน</Typography>
          </Box>
        </HideContentWrapper>
      )}
      {showContent && (
        <div>
          <Box mt={4}>
            <React.Suspense fallback={<Loader />}>
              <Summary />
              <PawnsTable />
            </React.Suspense>
          </Box>
        </div>
      )}
    </Container>
  )
}

export default Dashboard
