import styled from '@emotion/styled'

export const InformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const ReadFromIdButton = styled.button`
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
`
