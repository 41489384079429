import axiosInstance from './instance'

export type PointRequest = {
  id: string
  point: number
}

export type AmountRequest = {
  id: string
  amount: number
}

export type User = {
  id: string
  firstName: string
  lastName: string
  idCard?: string
  phone?: string
  amount?: number
  point?: number
  updatedDate?: string
}

export type Person = {
  firstName: string
  lastName: string
  idCard?: string
  phone?: string
}

const addPoint = ({ id, point }: PointRequest) =>
  axiosInstance.post(`/addPoint?id=${id}`, { point }).then(({ data }) => data)

const removePoint = ({ id, point }: PointRequest) =>
  axiosInstance.post(`/minusPoint?id=${id}`, { point }).then(({ data }) => data)

const fetchUsers = (query?: string) =>
  axiosInstance.get('/getUsers', { params: query ? { query } : undefined }).then(({ data }) => data)

const fetchUser = ({ id }: { id: string }) => axiosInstance.get(`/getUser?id=${id}`).then(({ data }) => data)

const addAmount = ({ id, amount }: AmountRequest) =>
  axiosInstance.post(`/addAmount?id=${id}`, { amount }).then(({ data }) => data)

const removeAmount = ({ id, amount }: AmountRequest) =>
  axiosInstance.post(`/minusAmount?id=${id}`, { amount }).then(({ data }) => data)

const createUser = (payload: Person) => axiosInstance.post('/createUser', { person: payload }).then(({ data }) => data)

const getSessionUserIdentity = () => axiosInstance.get('/getSessionUserIdentity').then(({ data }) => data)

const deleteSessionUserIdentity = () => axiosInstance.delete('/deleteSessionUserIdentity').then(({ data }) => data)

export const userService = {
  addAmount,
  addPoint,
  createUser,
  deleteSessionUserIdentity,
  fetchUser,
  fetchUsers,
  getSessionUserIdentity,
  removeAmount,
  removePoint,
}
