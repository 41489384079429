import { FetchPawnResponse, pawnService } from '@api/pawn-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import { GridContainer } from '@components/container'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { CommonCard } from '@components/styled/CommonCard'
import { Container } from '@components/styled/Container'
import SummaryHeader from '@components/SummaryHeader'
import { Box, Typography } from '@material-ui/core'
import { usePrintPawn } from '@shared/hooks/usePrintPawn'
import { useRouter } from '@shared/hooks/useRouter'
import { formatDate, formatNumber } from '@utils/format'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

const PawnExtendSummary = () => {
  const { query } = useRouter()
  const { data: pawn, error, isLoading, refetch } = useQuery<FetchPawnResponse>(`fetchPawn-${query.id}`, () =>
    pawnService.fetchPawn({ id: query?.id as string }),
  )
  const pawnSummary = queryClient.getQueryData<any>('pawn-extend-summary')
  const { print, isLoading: isPrinting } = usePrintPawn(query.id)

  if (isLoading) {
    return (
      <Container>
        <CommonCard>
          <Loader />
        </CommonCard>
      </Container>
    )
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Container>
      <CommonCard>
        <SummaryHeader title="ต่อดอก" />
        <GridContainer columns={2}>
          <Typography>เลขที่ใบจำนำ: </Typography>
          <Typography>{pawn?.id}</Typography>
          <Typography>วันเวลาที่ทำรายการ: </Typography>
          <Typography>{formatDate(pawn?.updatedDate)}</Typography>
        </GridContainer>
        <Box my={3}>
          <Typography variant="h5">ข้อมูลการต่อดอก</Typography>
        </Box>
        <GridContainer columns={2}>
          <Typography>ระยะเวลาครบกำหนด: </Typography>
          <Typography>{pawn?.targetMonth} เดือน</Typography>
          <Typography>วันครบกำหนด:</Typography>
          <Typography>{formatDate(pawn?.targetDate)}</Typography>
        </GridContainer>
        <Box my={3}>
          <Typography variant="h5">ข้อมูลจำนำ</Typography>
        </Box>
        <GridContainer columns={2}>
          <Typography>จำนวนวันดอกเบี้ย: </Typography>
          <Typography>{pawnSummary?.month} เดือน</Typography>
          <Typography>ดอกเบี้ย:</Typography>
          <Typography>{formatNumber(pawnSummary?.interest)} บาท</Typography>
          <Typography>คะแนนสะสมที่ได้รับ:</Typography>
          <Typography>{formatNumber(pawnSummary?.point)} คะแนน</Typography>
        </GridContainer>
        <Box my={3}>
          <Typography variant="h5">ข้อมูลผู้จำนำ</Typography>
          <Box mt={2}>
            <GridContainer columns={2}>
              <Typography>คะแนนสะสม: </Typography>
              <Typography>{formatNumber(pawnSummary?.user?.point)} </Typography>
            </GridContainer>
          </Box>
        </Box>
        <Box mt={4}>
          <Box display="flex">
            <Button type="button" isLoading={isPrinting} onClick={() => print()}>
              พิมพ์ใบจำนำ
            </Button>
            <Box ml={2}>
              <Link to="/">
                <Button variant="outlined">หน้าแรก</Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </CommonCard>
    </Container>
  )
}

export default PawnExtendSummary
