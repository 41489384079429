import { FetchPawnResponse, pawnService } from '@api/pawn-service'
import { User, userService } from '@api/user-service'
import Button from '@components/Button'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { Container } from '@components/styled/Container'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DepositHistoryModal from '@pages/UserDetail/DepositHistoryModal'
import { useRouter } from '@shared/hooks/useRouter'
import { formatDate, formatNumber } from '@utils/format'
import * as React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { GridDetail, IconWrapper, PawnTitleWrapper, TitleWrapper, Wrapper, Clickable } from './UserDetail.styled'

const headCells = [
  { id: 'id', label: 'เลขที่ใบจำนำ' },
  { id: 'firstName', label: 'ชื่อ' },
  { id: 'lastName', label: 'นามสกุล' },
  { id: 'goldTypes', label: 'ประเภทของ' },
  { id: 'priceAmount', label: 'จำนวนเงิน' },
  { id: 'updatedDate', label: 'วันเวลาที่ทำรายการ' },
  { id: 'action', label: '' },
]

const UserDetail = () => {
  const { history } = useRouter()
  const params = useParams<{ id: string }>()
  const { data: user, error, isLoading, refetch } = useQuery<User>(`fetchUser-${params.id}`, () =>
    userService.fetchUser({ id: params?.id as string }),
  )
  const { data: pawns } = useQuery<FetchPawnResponse[]>(`fetchPawns-${user?.firstName}-${user?.lastName}`, () =>
    pawnService.fetchPawns(`${user?.firstName}`),
  )
  const [isOpen, setIsOpen] = React.useState(false)

  const showDepositHistoryModal = () => {
    setIsOpen(true)
  }

  const filteredData = pawns?.filter(
    ({ person: { firstName, lastName } }) => firstName === user?.firstName && lastName === user?.lastName,
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const tableHead = (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell size="small" key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const tableBody = (
    <TableBody>
      {filteredData && filteredData?.length > 0 ? (
        filteredData
          ?.sort((a: any, b: any) => a.id - b.id)
          ?.map((pawn) => {
            const {
              id,
              person: { firstName, lastName },
              gold: { goldTypes, priceAmount },
              updatedDate,
            } = pawn
            return (
              <TableRow key={`unit-${id}`}>
                <TableCell size="small">{id}</TableCell>
                <TableCell size="small">{firstName}</TableCell>
                <TableCell size="small">{lastName}</TableCell>
                <TableCell size="small">{goldTypes.join(', ')}</TableCell>
                <TableCell size="small">{formatNumber(priceAmount)}</TableCell>
                <TableCell size="small">{formatDate(updatedDate, 'D MMM BBBB HH:mm')}</TableCell>
                <TableCell size="small" align="left">
                  <Link to={`/pawn/${id}`}>
                    <Typography color="primary">ดูใบจำนำ</Typography>
                  </Link>
                </TableCell>
              </TableRow>
            )
          })
      ) : (
        <TableRow>
          <TableCell size="small" colSpan={10}>
            ไม่พบใบจำนำ
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )

  if (isLoading) {
    return (
      <Container>
        <Wrapper>
          <Loader />
        </Wrapper>
      </Container>
    )
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <Typography variant="h4">
            {user?.firstName} {user?.lastName}
          </Typography>
          <IconWrapper onClick={handleClick} aria-haspopup="true">
            <MoreVertIcon fontSize="medium" style={{ color: '#333333' }} />
          </IconWrapper>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Paper>
              <List>
                <ListItem button onClick={() => history.push(`/user-purchase/${user?.id}`)}>
                  <ListItemText primary="ลูกค้าซื้อทอง" />
                </ListItem>
                <ListItem button onClick={() => history.push(`/user-sell/${user?.id}`)}>
                  <ListItemText primary="เพิ่มแต้มคะแนนให้ลูกค้า" />
                </ListItem>
                <ListItem button onClick={() => history.push(`/user-purchase/${user?.id}?type=change`)}>
                  <ListItemText primary="ลูกค้าเปลี่ยนทอง" />
                </ListItem>
                <ListItem button onClick={() => history.push(`/user-deposit/${user?.id}`)}>
                  <ListItemText primary="ฝากเงิน" />
                </ListItem>
                <ListItem button onClick={() => history.push(`/user-withdraw/${user?.id}`)}>
                  <ListItemText primary="ถอนเงิน" />
                </ListItem>
              </List>
            </Paper>
          </Popover>
        </TitleWrapper>
        <Box>
          <Typography variant="h5">ข้อมูลผู้จำนำ</Typography>
          <Box mt={2} pl={2}>
            <GridDetail>
              <Typography>ชื่อ:</Typography>
              <Typography>{user?.firstName}</Typography>
              <Typography>นามสกุล:</Typography>
              <Typography>{user?.lastName}</Typography>
              <Typography>รหัสบัตรประชาชน/หนังสือเดินทาง:</Typography>
              <Typography>{user?.idCard || '-'}</Typography>
              <Typography>เบอร์โทร:</Typography>
              <Typography>{user?.phone || '-'}</Typography>
              <Typography>คะแนนสะสม:</Typography>
              <Typography>{formatNumber(user?.point)}</Typography>
            </GridDetail>
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">ข้อมูลออมทอง</Typography>
          <Box mt={2} pl={2}>
            <GridDetail>
              <Typography>จำนวนเงิน:</Typography>
              <Box display="flex">
                <Typography>{formatNumber(user?.amount)} บาท</Typography>

                <Box ml={3}>
                  <Clickable onClick={() => showDepositHistoryModal()}>
                    <Typography color="primary">ประวัติการออม</Typography>
                  </Clickable>
                </Box>
              </Box>
            </GridDetail>
          </Box>
        </Box>
      </Wrapper>
      <Box mt={3}>
        <Paper>
          <Box p={4}>
            <PawnTitleWrapper>
              <Typography variant="h5">ใบจำนำ</Typography>
              <Link to={`/pawn?userId=${user?.id}`}>
                <Button variant="outlined">เพิ่มใบจำนำ</Button>
              </Link>
            </PawnTitleWrapper>
            <Box mt={2}>
              <Table>
                {tableHead}
                {tableBody}
              </Table>
            </Box>
          </Box>
        </Paper>
      </Box>
      <DepositHistoryModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        id={user?.id}
        name={`${user?.firstName} ${user?.lastName}`}
      />
    </Container>
  )
}

export default UserDetail
