import Navbar from '@components/Layout/Navbar'
import Box from '@material-ui/core/Box'
import * as React from 'react'

const Layout: React.FC = ({ children }) => {
  return (
    <div>
      <Navbar />
      <Box py={5}>{children}</Box>
    </div>
  )
}

export default Layout
