import Button from '@components/Button'
import { useNotification } from '@components/notification'
import { Box, TextField, Typography } from '@material-ui/core'
import firebase from '@utils/firebase'
import { makeFieldProps } from '@utils/form'
import { useFormik } from 'formik'
import * as React from 'react'
import { useMutation } from 'react-query'
import * as Yup from 'yup'
import { Container, Wrapper } from './Login.styled'

type FormSchema = {
  email: string
  password: string
}

const schema = Yup.object().shape({
  email: Yup.string().email('ต้องเป็นรูปแบบอีเมลที่ถูกต้อง').max(150).required('กรุณากรอกอีเมล'),
  password: Yup.string().max(150).required('กรุณากรอกรหัสผ่าน'),
})

const Login: React.FC = () => {
  const { pushNotification } = useNotification()
  const { mutate, isLoading } = useMutation(
    (values: { email: string; password: string }) =>
      firebase.auth().signInWithEmailAndPassword(values.email, values.password),
    {
      mutationKey: 'login',
      onError: (error: firebase.auth.Error) => {
        if (error.code === 'auth/user-not-found') {
          pushNotification('อีเมลหรือรหัสผ่านไม่ถูกต้อง')
        } else {
          pushNotification(error.message)
        }
      },
    },
  )
  const { handleChange, handleBlur, values, handleSubmit, errors, touched } = useFormik<FormSchema>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      mutate(values)
    },
  })

  const { getFieldProps } = makeFieldProps({ values, errors, touched, onBlur: handleBlur, onChange: handleChange })

  return (
    <Wrapper>
      <Container>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Box mb={5}>
            <Typography variant="h3">เข้าสู่ระบบ</Typography>
          </Box>
          <Box mb={3}>
            <TextField variant="outlined" label="อีเมล" fullWidth {...getFieldProps('email')} />
          </Box>
          <Box mb={4}>
            <TextField variant="outlined" type="password" label="รหัสผ่าน" fullWidth {...getFieldProps('password')} />
          </Box>
          <Button type="submit" isLoading={isLoading} fullWidth>
            เข้าสู่ระบบ
          </Button>
        </form>
      </Container>
    </Wrapper>
  )
}

export default Login
