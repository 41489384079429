import instance from '@api/instance'
import { queryClient } from '@app/query-client'
import Routes from '@app/Routes'
import Layout from '@components/Layout'
import { ConfirmModalProvider } from '@components/Modal/ConfirmModal'
import { NotificationProvider } from '@components/notification'
import { Global, ThemeProvider } from '@emotion/react'
import { CssBaseline, MuiThemeProvider, StylesProvider } from '@material-ui/core'
import Login from '@pages/Login'
import { globalStyle } from '@styles/global'
import { theme } from '@styles/theme'
import firebase from '@utils/firebase'
import React from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const PrivateRoute: React.FC<any> = ({ children }) => {
  const [isAuth, setIsAuth] = React.useState<boolean | undefined>()
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      instance.interceptors.request.use(async (config) => {
        const token = await user.getIdToken()

        if (token) {
          config.headers.authorization = `Bearer ${token}`
        }

        return config
      })
    }

    setIsAuth(!!user)
  })

  if (isAuth === undefined) {
    return null
  }

  return !isAuth ? <Login /> : children
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={true} />
      <NotificationProvider>
        <CssBaseline />
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <ConfirmModalProvider>
                <Global styles={globalStyle} />
                <PrivateRoute>
                  <Layout>
                    <Routes />
                  </Layout>
                </PrivateRoute>
              </ConfirmModalProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </NotificationProvider>
    </QueryClientProvider>
  )
}

export default App
