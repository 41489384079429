import { transactionService, TransactionType } from '@api/transaction-service'
import { User, userService } from '@api/user-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { useConfirm } from '@components/Modal/ConfirmModal'
import { useNotification } from '@components/notification'
import { CommonCard } from '@components/styled/CommonCard'
import { Container } from '@components/styled/Container'
import { Box, TextField, Typography } from '@material-ui/core'
import { useRouter } from '@shared/hooks/useRouter'
import * as React from 'react'
import { useMutation, useQuery } from 'react-query'

const GoldSell = () => {
  const { confirm } = useConfirm()
  const { pushDefaultError, pushNotification } = useNotification()
  const { query, history } = useRouter()
  const { data: user, error, isLoading, refetch } = useQuery<User>(`fetchUser-${query.id}`, () =>
    userService.fetchUser({ id: query?.id as string }),
  )
  const { mutate: sellGold, isLoading: isUpdating } = useMutation(userService.addPoint, {
    mutationKey: 'sellGold',
    onSuccess: async () => {
      queryClient.invalidateQueries(`fetchUser-${query.id}`)

      transactionService.createTransaction({
        info: user,
        type: TransactionType.SELL,
        change: {
          point,
          interest: 0,
          price: 0,
          weight: 0,
        },
      })

      pushNotification('บันทึกข้อมูลสำเร็จ')

      history.push(`/user/${query.id}`)
    },
    onError: () => {
      pushDefaultError()
    },
  })
  const [point, setPoint] = React.useState(0)

  const handleSell = () => {
    if (user) {
      // @ts-ignore
      if (point < 0) {
        pushNotification('ไม่สามารถเพิ่มคะแนนสะสมติดลบได้')
      } else {
        confirm(() =>
          sellGold({
            id: user.id,
            point,
          }),
        )
      }
    }
  }

  if (isLoading) {
    return (
      <Container>
        <CommonCard>
          <Loader />
        </CommonCard>
      </Container>
    )
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Container>
      <CommonCard>
        <Typography variant="h5">เพิ่มแต้มคะแนนให้ลูกค้า</Typography>
        <Box mt={3}>
          <Box mt={3}>
            <TextField
              label="คะแนนที่ต้องการเพิ่ม"
              fullWidth
              variant="outlined"
              name="point"
              value={point.toString()}
              type="number"
              onChange={({ target: { value } }) => setPoint(+value)}
            />
          </Box>
          <Box mt={4}>
            <Button onClick={handleSell} isLoading={isUpdating}>
              บันทึก
            </Button>
          </Box>
        </Box>
      </CommonCard>
    </Container>
  )
}

export default GoldSell
