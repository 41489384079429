import { Container } from '@components/styled/Container'
import { Typography } from '@material-ui/core'
import Dashboard from '@pages/Dashboard'
import Deposit from '@pages/Deposit'
import GoldPurchase from '@pages/GoldPurchase'
import GoldSell from '@pages/GoldSell'
import NewUser from '@pages/NewUser'
import Pawn from '@pages/Pawn'
import PawnAdjust from '@pages/PawnAdjust'
import PawnAdjustSummary from '@pages/PawnAdjust/PawnAdjustSummary'
import PawnDetail from '@pages/PawnDetail'
import PawnExtend from '@pages/PawnExtend'
import PawnExtendSummary from '@pages/PawnExtend/PawnExtendSummary'
import PawnRemove from '@pages/PawnRemove'
import PawnTakeout from '@pages/PawnTakeout'
import PawnUndo from '@pages/PawnUndo'
import Search from '@pages/Search'
import SearchUser from '@pages/SearchUser'
import Setting from '@pages/Setting'
import UserDetail from '@pages/UserDetail'
import Withdraw from '@pages/Withdraw'
import React from 'react'
import { Route, Switch } from 'react-router'

const NotFound = () => (
  <Container>
    <Typography variant="h5">ไม่พบหน้าที่ต้องการค้นหา กรุณาลองใหม่อีกครั้ง</Typography>
  </Container>
)

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/pawn" component={Pawn} />
    <Route exact path="/search" component={Search} />
    <Route exact path="/search-user" component={SearchUser} />
    <Route exact path="/new-user" component={NewUser} />
    <Route exact path="/pawn-undo" component={PawnUndo} />
    <Route exact path="/pawn/:id" component={PawnDetail} />
    <Route exact path="/user/:id" component={UserDetail} />
    <Route exact path="/user-purchase/:id" component={GoldPurchase} />
    <Route exact path="/user-sell/:id" component={GoldSell} />
    <Route exact path="/user-deposit/:id" component={Deposit} />
    <Route exact path="/user-withdraw/:id" component={Withdraw} />
    <Route exact path="/pawn-extend/:id" component={PawnExtend} />
    <Route exact path="/pawn-adjust/:id" component={PawnAdjust} />
    <Route exact path="/pawn-takeout/:id" component={PawnTakeout} />
    <Route exact path="/pawn-remove/:id" component={PawnRemove} />
    <Route exact path="/pawn-extend/:id/summary" component={PawnExtendSummary} />
    <Route exact path="/pawn-adjust/:id/summary" component={PawnAdjustSummary} />
    <Route exact path="/setting" component={Setting} />
    <Route path="*" component={NotFound} />
  </Switch>
)

export default Routes
