import styled from '@emotion/styled'

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const HideContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: ${({ theme }) => theme.custom.colors.text2};
  flex-direction: column;
  min-height: 600px;
`
