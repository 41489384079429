import { FetchPawnResponse, pawnService } from '@api/pawn-service'
import Button from '@components/Button'
import { GridContainer } from '@components/container'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { useNotification } from '@components/notification'
import { Box, Typography } from '@material-ui/core'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import firebase from '@utils/firebase'
import { formatNumber } from '@utils/format'
import queryString from 'query-string'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import dayjs from '@utils/dayjs'
import { CheckWrapper, TitleWrapper, Wrapper } from './Summary.styled'

const Summary = () => {
  const { pushDefaultError } = useNotification()
  const location = useLocation()
  const params = queryString.parse(location.search)
  const { data: pawn, error, isLoading, refetch } = useQuery<FetchPawnResponse>(`fetchPawn-${params.id}`, () =>
    pawnService.fetchPawn({ id: params.id as string }),
  )
  const { isLoading: isPdfLoading, refetch: refetchPdf } = useQuery<{ path: string }>(
    `fetchPDFUrl-${params.id}`,
    () => pawnService.fetchPDFUrl({ id: params?.id as string }),
    {
      cacheTime: 0,
      enabled: false,
      retry: false,
      onError: () => {
        pushDefaultError()
      },
      onSuccess: async (data) => {
        const storageRef = firebase.storage().ref()
        const pdfRef = storageRef.child(data.path)

        try {
          const url = await pdfRef.getDownloadURL()
          window.open(url, '_blank')
        } catch (_) {
          pushDefaultError()
        }
      },
    },
  )

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Wrapper>
      <CheckWrapper>
        <CheckCircleIcon style={{ fontSize: 80 }} />
      </CheckWrapper>
      <TitleWrapper>
        <Typography variant="h5">เพิ่มข้อมูลจำนำ</Typography>
      </TitleWrapper>
      <GridContainer columns={2}>
        <Typography>เลขที่ใบจำนำ: </Typography>
        <Typography>{pawn?.id}</Typography>
        <Typography>วันที่ทำรายการ: </Typography>
        <Typography>{dayjs(pawn?.updatedDate).format('D MMM BBBB HH:mm')}</Typography>
      </GridContainer>
      <Box mt={3}>
        <Typography variant="h5">ข้อมูลผู้จำนำ</Typography>
        <Box pl={2} pt={3}>
          <GridContainer columns={2}>
            <Typography>ชื่อ:</Typography>
            <Typography>{pawn?.person.firstName}</Typography>
            <Typography>นามสกุล:</Typography>
            <Typography>{pawn?.person.lastName}</Typography>
            <Typography>รหัสบัตรประชาชน/หนังสือเดินทาง:</Typography>
            <Typography>{pawn?.person.idCard || '-'}</Typography>
            <Typography>เบอร์โทร:</Typography>
            <Typography>{pawn?.person.phone || '-'}</Typography>
          </GridContainer>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">ข้อมูลของ</Typography>
          <Box pl={2} pt={3}>
            <GridContainer columns={2}>
              <Typography>ประเภททอง:</Typography>
              <Typography>{pawn?.gold.goldTypes.join(', ')}</Typography>
              <Typography>น้ำหนักทอง:</Typography>
              <Typography>{pawn?.gold.goldWeight} กรัม</Typography>
              <Typography>จำนวนเงิน:</Typography>
              <Typography>{formatNumber(pawn?.gold.priceAmount)} บาท</Typography>
              <Typography>ข้อมูลเพิ่มเติม:</Typography>
              <Typography>{pawn?.gold.additionalInfo?.join(', ') || '-'}</Typography>
            </GridContainer>
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">ครบกำหนด</Typography>
          <Box pl={2} pt={3}>
            <GridContainer columns={2}>
              <Typography>ระยะเวลาครบกำหนด:</Typography>
              <Typography>{pawn?.targetMonth} เดือน</Typography>
              <Typography>วันที่ครบกำหนด:</Typography>
              <Typography>{dayjs(pawn?.targetDate).format('D MMM BBBB')}</Typography>
            </GridContainer>
          </Box>
        </Box>
        <Box mt={3}>
          <Box display="flex">
            <Button type="button" isLoading={isPdfLoading} onClick={() => refetchPdf()}>
              พิมพ์ใบจำนำ
            </Button>
            <Box ml={2}>
              <Link to="/">
                <Button variant="outlined">หน้าแรก</Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  )
}

export default Summary
