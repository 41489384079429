import { FetchPawnResponse, pawnService } from '@api/pawn-service'
import { transactionService, TransactionType } from '@api/transaction-service'
import { userService } from '@api/user-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import Loader from '@components/Loader'
import { useConfirm } from '@components/Modal/ConfirmModal'
import { useNotification } from '@components/notification'
import { Container } from '@components/styled/Container'
import { Clickable } from '@components/styled/UI'
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import { formatDate, formatNumber } from '@utils/format'
import * as React from 'react'
import { useMutation, useQuery } from 'react-query'
import { Wrapper } from './PawnUndo.styled'

const headCells = [
  { id: 'id', label: 'เลขที่ใบจำนำ' },
  { id: 'firstName', label: 'ชื่อ' },
  { id: 'lastName', label: 'นามสกุล' },
  { id: 'goldTypes', label: 'ประเภทของ' },
  { id: 'priceAmount', label: 'จำนวนเงิน' },
  { id: 'updatedDate', label: 'วันเวลาที่ทำรายการ' },
  { id: 'action', label: '' },
]

const PawnUndo = () => {
  const { confirm } = useConfirm()
  const { pushDefaultError } = useNotification()
  const [query, setQuery] = React.useState('')
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const { data, isLoading } = useQuery<FetchPawnResponse[]>(
    `fetchPawnRemoved-${searchKeyword}`,
    () => pawnService.fetchAllRemovedPawns(searchKeyword || undefined),
    {
      cacheTime: 0,
    },
  )
  const { mutate: undoPawn } = useMutation((id: any) => pawnService.undoPawn({ id }), {
    onSuccess: async (values) => {
      const transactions = await transactionService.getUserTransactions({ id: values.id })

      const [latestTransaction] = transactions // @ts-ignore
        ?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))

      let point = 0
      let interest = 0

      if (latestTransaction?.type === TransactionType.TAKE_OUT) {
        point = point - latestTransaction.change.point
        interest = interest - latestTransaction.change.interest

        await userService.removePoint({ id: latestTransaction.info?.user?.id, point: latestTransaction.change.point })
      }

      await transactionService.createTransaction({
        info: {
          ...values,
        },
        type: TransactionType.UNDO,
        change: {
          point,
          interest,
          price: values.gold.priceAmount,
          weight: values.gold.goldWeight,
        },
      })

      queryClient.invalidateQueries()
    },
    onError: (err) => {
      console.log('err >>>', err)
      pushDefaultError()
    },
  })
  const { mutate: clearOldPawns } = useMutation(() => pawnService.clearOldPawns(), {
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
    onError: () => {
      pushDefaultError()
    },
  })

  const [, forceRerender] = React.useState({})

  const filteredData = data?.filter(
    ({ id, person: { firstName, lastName, idCard } }) =>
      !searchKeyword || [id, firstName, lastName, idCard].some((data: any) => String(data).includes(searchKeyword)),
  )

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setSearchKeyword(query)
    forceRerender({})
  }

  const tableHead = (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell size="small" key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const tableBody = (
    <TableBody>
      {filteredData && filteredData?.length > 0 ? (
        filteredData
          // @ts-ignore
          ?.sort((a: any, b: any) => new Date(b.deletedDate || 0).getTime() - new Date(a.deletedDate || 0).getTime())
          ?.map((pawn) => {
            const {
              id,
              person: { firstName, lastName },
              gold: { goldTypes, priceAmount },
              updatedDate,
            } = pawn
            return (
              <TableRow key={`unit-${id}`}>
                <TableCell size="small">{id}</TableCell>
                <TableCell size="small">{firstName}</TableCell>
                <TableCell size="small">{lastName}</TableCell>
                <TableCell size="small">{goldTypes.join(', ')}</TableCell>
                <TableCell size="small">{formatNumber(priceAmount)}</TableCell>
                <TableCell size="small">{formatDate(updatedDate, 'D MMM BBBB HH:mm')}</TableCell>
                <TableCell size="small" align="left">
                  <Clickable onClick={() => confirm(() => undoPawn(id))}>
                    <Typography color="primary">กู้คืนใบจำนำ</Typography>
                  </Clickable>
                </TableCell>
              </TableRow>
            )
          })
      ) : (
        <TableRow>
          <TableCell size="small" colSpan={10}>
            ไม่พบใบจำนำ
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )

  return (
    <Container>
      <Wrapper>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h4">ค้นหาข้อมูล</Typography>
          <Box display="flex" justifyContent="space-between" alignItems="flex-end" flexDirection="column">
            <Clickable
              onClick={() => {
                confirm(() => clearOldPawns())
              }}
            >
              <Typography color="primary" style={{ width: 'fit-content', marginLeft: 'auto' }}>
                ล้างข้อมูลถาวร
              </Typography>
            </Clickable>
            <Typography variant="caption" color="textSecondary">
              *ข้อมูลที่ไม่มีการทำรายการเกิน 180 วันจะถูกลบทิ้ง
            </Typography>
          </Box>
        </Box>
        <form onSubmit={handleSearch}>
          <Box mt={3}>
            <TextField
              variant="outlined"
              placeholder="ค้นหาหมายเลขใบจำนำ, ชื่อ, นามสกุล, รหัสบัตรประชาชน/หนังสือเดินทาง"
              fullWidth
              value={query}
              onChange={(event) => setQuery(event.target.value)}
            />
          </Box>
          <Box mt={3}>
            <Button type="submit">ค้นหา</Button>
          </Box>
        </form>
      </Wrapper>
      <Box mt={2}>
        {
          <Paper>
            {isLoading && (
              <Box p={3}>
                <Loader />
              </Box>
            )}
            {!isLoading && (
              <Box p={4}>
                <Typography variant="h5">แสดง {filteredData?.length} ใบจำนำ</Typography>
                <Box mt={2}>
                  <Table>
                    {tableHead}
                    {tableBody}
                  </Table>
                </Box>
              </Box>
            )}
          </Paper>
        }
      </Box>
    </Container>
  )
}

export default PawnUndo
