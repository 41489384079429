import { FetchPawnResponse, pawnService } from '@api/pawn-service'
import { SettingPayload, settingService } from '@api/setting-service'
import { transactionService, TransactionType } from '@api/transaction-service'
import { userService } from '@api/user-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { useConfirm } from '@components/Modal/ConfirmModal'
import { useNotification } from '@components/notification'
import Select from '@components/Select'
import { CommonCard } from '@components/styled/CommonCard'
import { Container } from '@components/styled/Container'
import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core'
import { CheckboxContainer } from '@pages/Pawn/components/ItemForm/ItemForm.styled'
import { additionalInfos, goldTypes } from '@shared/constants/gold'
import { useRouter } from '@shared/hooks/useRouter'
import dayjs from '@utils/dayjs'
import { makeFieldProps } from '@utils/form'
import { atLeast } from '@utils/format'
import { getPawnInterest } from '@utils/pawn'
import { useFormik } from 'formik'
import * as React from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { GridContainer } from './PawnAdjust.styled'

export type FormSchema = {
  goldTypes: string[]
  goldCustomType: string
  goldWeight: string
  priceAmount: string
  additionalInfo: string[]
  targetMonth: number
}

const schema = Yup.object().shape({
  goldTypes: Yup.array(),
  goldCustomType: Yup.string(),
  goldWeight: Yup.number().typeError('กรุณากรอกเฉพาะตัวเลข').required('กรุณากรอกน้ำหนักทอง (กรัม)'),
  priceAmount: Yup.number().typeError('กรุณากรอกเฉพาะตัวเลข').required('กรุณากรอกจำนวนเงิน (บาท)'),
  additionalInfo: Yup.array(),
  targetMonth: Yup.number(),
})

const PawnAdjust = () => {
  const { confirm } = useConfirm()
  const { pushDefaultError } = useNotification()
  const { query, history } = useRouter()
  const { data: setting, isLoading: isSettingLoading } = useQuery<SettingPayload>('fetchSetting', () =>
    settingService.fetchSetting(),
  )
  const { data: pawn, error, isLoading, refetch } = useQuery<FetchPawnResponse>(`fetchPawn-${query.id}`, () =>
    pawnService.fetchPawn({ id: query?.id as string }),
  )
  const { mutate: updatePawn, isLoading: isUpdating } = useMutation(pawnService.updatePawn, {
    mutationKey: 'updatePawn',
    onSuccess: async () => {
      if (setting && pawn) {
        const interest = atLeast(
          getPawnInterest({ pawnDate: pawn.updatedDate, amount: pawn.gold.priceAmount }, setting),
        )
        const dateDiff = atLeast(
          dayjs(dayjs().set('hour', 0).toISOString()).diff(dayjs(pawn.updatedDate).set('hour', 0), 'day'),
        )
        const point = atLeast(Number(parseFloat(String((interest * setting.rateConverter) / 100)).toFixed(2)))
        const user = await userService.addPoint({ id: pawn.user.id, point })

        queryClient.setQueryData('pawn-adjust-summary', () => ({
          interest,
          point,
          day: dateDiff,
          user,
        }))

        let weightSum: number = +values.goldWeight - +pawn.gold.goldWeight
        const isFakeGold = pawn?.gold?.additionalInfo?.includes('ทอง 90')
        const isCurrentFakeGold = values.additionalInfo.includes('ทอง 90')

        if ((!isFakeGold && !isCurrentFakeGold) || (isFakeGold && isCurrentFakeGold)) {
          // Not change from 90 to 96.5 and not change from 96.5 to 90
          weightSum = +values.goldWeight - +pawn.gold.goldWeight
        } else if (isFakeGold && !isCurrentFakeGold) {
          // from 90 to 96.5
          weightSum = +values.goldWeight
        } else if (!isFakeGold && isCurrentFakeGold) {
          // from 96.5 to 90
          weightSum = -values.goldWeight
        }

        await transactionService.createTransaction({
          info: {
            ...pawn,
            gold: {
              ...pawn.gold,
              additionalInfo:
                (isFakeGold && !isCurrentFakeGold) || (!isFakeGold && isCurrentFakeGold)
                  ? pawn.gold?.additionalInfo?.filter((i) => i !== 'ทอง 90')
                  : pawn.gold?.additionalInfo,
            },
          },
          type: TransactionType.ADJUST,
          change: {
            point,
            interest,
            price: +values.priceAmount - +pawn.gold.priceAmount,
            weight: weightSum,
          },
        })

        queryClient.invalidateQueries()

        history.push(`/pawn-adjust/${query.id}/summary`)
      } else {
        pushDefaultError()
      }
    },
    onError: () => {
      pushDefaultError()
    },
  })
  const {
    handleChange,
    handleBlur,
    values,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik<FormSchema>({
    initialValues: {
      goldTypes: [],
      goldCustomType: '',
      goldWeight: '',
      priceAmount: '',
      additionalInfo: [],
      targetMonth: 2,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      confirm(() =>
        updatePawn({
          id: query.id,
          gold: {
            goldTypes: values.goldTypes.map((type) => (type !== 'อื่น ๆ' ? type : values.goldCustomType)),
            goldCustomType: values.goldTypes.includes('อื่น ๆ') ? values.goldCustomType : '',
            goldWeight: +values.goldWeight,
            priceAmount: +values.priceAmount,
            additionalInfo: values.additionalInfo,
          },
          targetMonth: values.targetMonth,
          targetDate: dayjs().add(values.targetMonth, 'month').toISOString(),
        }),
      )
    },
  })

  React.useEffect(() => {
    if (pawn) {
      setValues({
        goldCustomType: pawn.gold.goldCustomType || '',
        goldWeight: String(pawn.gold.goldWeight),
        priceAmount: String(pawn.gold.priceAmount),
        additionalInfo: pawn.gold.additionalInfo || [],
        goldTypes: pawn.gold.goldTypes.map((type) => (type !== pawn.gold.goldCustomType ? type : 'อื่น ๆ')),
        targetMonth: 2,
      })
    }
  }, [pawn, setValues])

  const { getFieldProps } = makeFieldProps({ values, errors, touched, onBlur: handleBlur, onChange: handleChange })

  if (isLoading || isSettingLoading) {
    return (
      <Container>
        <CommonCard>
          <Loader />
        </CommonCard>
      </Container>
    )
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Container>
      <CommonCard>
        <form onSubmit={handleSubmit}>
          <Typography variant="h5">เพิ่ม/ลดยอด</Typography>
          <Box mt={3}>
            <GridContainer>
              <Typography>เลขที่ใบจำนำ:</Typography>
              <Typography>{pawn?.id}</Typography>
            </GridContainer>
          </Box>
          <Box my={3}>
            <Box mb={3}>
              <Typography variant="h5">ข้อมูลของ</Typography>
            </Box>
            <Typography>ประเภททอง</Typography>

            <Box pl={2} pt={3}>
              <CheckboxContainer>
                {goldTypes.map(({ name, label }) => (
                  <FormControlLabel
                    key={name}
                    control={
                      <Checkbox
                        name="goldTypes"
                        color="primary"
                        onChange={(event) => {
                          if (event.target.checked) {
                            const newGoldTypes = [...values.goldTypes, label]
                            setFieldValue('goldTypes', newGoldTypes)
                          } else {
                            const newGoldTypes = values.goldTypes.filter((type) => type !== label)
                            setFieldValue('goldTypes', newGoldTypes)
                          }
                        }}
                        checked={values.goldTypes.includes(label)}
                      />
                    }
                    label={label}
                  />
                ))}
              </CheckboxContainer>
            </Box>
            {values.goldTypes.includes('อื่น ๆ') && (
              <Box mt={3}>
                <TextField variant="outlined" label="ระบุประเภททอง" fullWidth {...getFieldProps('goldCustomType')} />
              </Box>
            )}
            <Box mt={3}>
              <TextField variant="outlined" label="น้ำหนักทอง (กรัม)" fullWidth {...getFieldProps('goldWeight')} />
            </Box>
            <Box mt={3}>
              <TextField variant="outlined" label="จำนวนเงิน (บาท)" fullWidth {...getFieldProps('priceAmount')} />
            </Box>
            <Box pt={3}>
              <Typography>ข้อมูลเพิ่มเติม</Typography>
            </Box>
            <Box pl={2} pt={2}>
              <CheckboxContainer>
                {additionalInfos.map(({ name, label }) => (
                  <FormControlLabel
                    key={name}
                    control={
                      <Checkbox
                        name="additionalInfo"
                        color="primary"
                        onChange={(event) => {
                          if (event.target.checked) {
                            const newInfos = [...values.additionalInfo, label]
                            setFieldValue('additionalInfo', newInfos)
                          } else {
                            const newInfos = values.additionalInfo.filter((type) => type !== label)
                            setFieldValue('additionalInfo', newInfos)
                          }
                        }}
                        checked={values.additionalInfo.includes(label)}
                      />
                    }
                    label={label}
                  />
                ))}
              </CheckboxContainer>
            </Box>
          </Box>
          <Typography variant="h5">วันครบกำหนด</Typography>
          <Box pt={3}>
            <Select
              label="ระยะเวลาครบกำหนด"
              value={values.targetMonth}
              options={Array.from({ length: 12 }).map((_, index) => ({
                label: `${index + 1} เดือน`,
                value: index + 1,
              }))}
              onChange={(value) => setFieldValue('targetMonth', value)}
              fullWidth
            />
          </Box>
          <Box pt={3}>
            <GridContainer>
              <Typography>วันครบกำหนด:</Typography>
              <Typography>{dayjs().add(values.targetMonth, 'months').format('D MMM BBBB')}</Typography>
            </GridContainer>
          </Box>
          <Box mt={4} display="flex">
            <Button type="submit" isLoading={isUpdating}>
              เพิ่ม/ลดยอด
            </Button>
            <Box ml={2}>
              <Link to={`/pawn/${query.id}`}>
                <Button variant="outlined">ยกเลิก</Button>
              </Link>
            </Box>
          </Box>
        </form>
      </CommonCard>
    </Container>
  )
}

export default PawnAdjust
