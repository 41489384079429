export const goldTypes = [
  {
    name: 't1',
    label: 'แหวน',
  },
  {
    name: 't2',
    label: 'ต่างหู',
  },
  {
    name: 't3',
    label: 'สร้อยคอ',
  },
  {
    name: 't4',
    label: 'พระเลี่ยมทอง',
  },
  {
    name: 't5',
    label: 'สร้อยข้อมือ',
  },
  {
    name: 't6',
    label: 'จี้',
  },
  {
    name: 't7',
    label: 'กำไล',
  },
  {
    name: 't8',
    label: 'อื่น ๆ',
  },
]

export const additionalInfos = [
  {
    name: 't1',
    label: 'ทอง 90',
  },
  {
    name: 't2',
    label: 'ชำรุด',
  },
]
