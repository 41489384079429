import { SettingPayload, settingService } from '@api/setting-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import Loader from '@components/Loader'
import { useNotification } from '@components/notification'
import { CommonCard } from '@components/styled/CommonCard'
import { Container } from '@components/styled/Container'
import { Box, TextField, Typography } from '@material-ui/core'
import { makeFieldProps } from '@utils/form'
import { useFormik } from 'formik'
import * as React from 'react'
import { useMutation, useQuery } from 'react-query'
import * as Yup from 'yup'

const schema = Yup.object().shape({
  interestRate: Yup.number().min(0).required('กรุณากรอก').typeError('กรุณากรอกเฉพาะตัวเลข'),
  rateConverter: Yup.number().min(0).required('กรุณากรอก').typeError('กรุณากรอกเฉพาะตัวเลข'),
})

const Setting = () => {
  const { pushDefaultError, pushNotification } = useNotification()
  const { data, isLoading } = useQuery<SettingPayload>('fetchSetting', () => settingService.fetchSetting())
  const { mutate, isLoading: isUpdating } = useMutation(
    (values: SettingPayload) => settingService.updateSetting(values),
    {
      onSuccess: async () => {
        pushNotification('ตั้งค่าเสร็จสิ้น')
        await queryClient.invalidateQueries()
      },
      onError: () => {
        pushDefaultError()
      },
    },
  )
  const { handleChange, handleBlur, values, handleSubmit, errors, touched, setValues } = useFormik<SettingPayload>({
    initialValues: {
      interestRate: 0,
      rateConverter: 0,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      mutate({
        interestRate: values.interestRate,
        rateConverter: values.rateConverter,
      })
    },
  })

  React.useEffect(() => {
    if (data) {
      setValues(data)
    }
  }, [data, setValues])

  const { getFieldProps } = makeFieldProps({ values, errors, touched, onBlur: handleBlur, onChange: handleChange })

  if (isLoading && !data) {
    return (
      <Container>
        <CommonCard>
          <Loader />
        </CommonCard>
      </Container>
    )
  }

  return (
    <Container>
      <CommonCard>
        <Typography variant="h4">ตั้งค่าระบบ</Typography>
        <form onSubmit={handleSubmit}>
          <Box mt={4}>
            <TextField label="ดอกเบี้ยต่อเดือน (%)" fullWidth variant="outlined" {...getFieldProps('interestRate')} />
          </Box>
          <Box mt={4}>
            <TextField
              label="ตัวแปลงดอกเบี้ยเป็นคะแนน (%)"
              fullWidth
              variant="outlined"
              {...getFieldProps('rateConverter')}
            />
          </Box>
          <Box mt={4}>
            <Button type="submit" isLoading={isUpdating}>
              บันทึก
            </Button>
          </Box>
        </form>
      </CommonCard>
    </Container>
  )
}

export default Setting
