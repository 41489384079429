import { FetchPawnResponse, pawnService } from '@api/pawn-service'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { formatDate, formatNumber } from '@utils/format'
import dayjs from 'dayjs'
import * as React from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

const headCells = [
  { id: 'id', label: 'เลขที่ใบจำนำ' },
  { id: 'firstName', label: 'ชื่อ' },
  { id: 'lastName', label: 'นามสกุล' },
  { id: 'goldTypes', label: 'ประเภทของ' },
  { id: 'goldWeight', label: 'น้ำหนักทอง' },
  { id: 'priceAmount', label: 'จำนวนเงิน' },
  { id: 'updatedDate', label: 'วันเวลาที่ทำรายการ' },
  { id: 'targetDate', label: 'วันครบกำหนด' },
  { id: 'action', label: '' },
]

const PawnsTable = () => {
  const { data, error, isLoading, refetch } = useQuery<FetchPawnResponse[]>(`fetchOutdatedPawns`, () =>
    pawnService.fetchAllPawns(),
  )

  const filteredData = data?.filter((pawn) => dayjs().diff(dayjs(pawn.targetDate), 'day') > 0)

  if (error) {
    return <Error onRetry={refetch} />
  }

  if (isLoading) {
    return (
      <Paper>
        <Box p={4}>
          <Loader />
        </Box>
      </Paper>
    )
  }

  const tableHead = (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell size="small" key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const tableBody = (
    <TableBody>
      {filteredData && filteredData?.length > 0 ? (
        filteredData
          // @ts-ignore
          ?.sort((a: any, b: any) => new Date(a.targetDate) - new Date(b.targetDate))
          ?.map((pawn) => {
            const {
              id,
              person: { firstName, lastName },
              gold: { goldTypes, priceAmount, goldWeight },
              updatedDate,
              targetDate,
            } = pawn

            const color = {
              red: 'rgb(255 0 0 / 30%)',
              yellow: 'rgb(230 255 24 / 30%)',
              orange: 'rgb(255 83 0 / 30%)',
            }

            const overdueMonth = dayjs().diff(dayjs(pawn.targetDate), 'month')

            const getStyleColor = () => {
              if (overdueMonth < 1) {
                return color.yellow
              } else if (overdueMonth < 2) {
                return color.orange
              }

              return color.red
            }

            const styleColor = getStyleColor()

            return (
              <TableRow key={`unit-${id}`} style={{ background: styleColor }}>
                <TableCell size="small">{id}</TableCell>
                <TableCell size="small">{firstName}</TableCell>
                <TableCell size="small">{lastName}</TableCell>
                <TableCell size="small">{goldTypes.join(', ')}</TableCell>
                <TableCell size="small">{goldWeight}</TableCell>
                <TableCell size="small">{formatNumber(priceAmount)}</TableCell>
                <TableCell size="small">{formatDate(updatedDate, 'D MMM BBBB HH:mm')}</TableCell>
                <TableCell size="small">{formatDate(targetDate)}</TableCell>
                <TableCell size="small" align="left">
                  <Link to={`/pawn/${id}`}>
                    <Typography color="primary">ดูใบจำนำ</Typography>
                  </Link>
                </TableCell>
              </TableRow>
            )
          })
      ) : (
        <TableRow>
          <TableCell size="small" colSpan={10}>
            ไม่พบใบจำนำ
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )

  return (
    <Paper>
      <Box p={4}>
        <Typography variant="h5">แสดง {filteredData?.length} ใบจำนำที่เลยกำหนด</Typography>
        <Box mt={2}>
          <Table>
            {tableHead}
            {tableBody}
          </Table>
        </Box>
      </Box>
    </Paper>
  )
}

export default PawnsTable
