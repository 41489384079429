import { Container } from '@components/styled/Container'
import { List, ListItem, ListItemText, Paper, Popover } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { useRouter } from '@shared/hooks/useRouter'
import firebase from '@utils/firebase'
import * as React from 'react'
import { IconWrapper, TabItem, TabList, Wrapper } from './Navbar.styled'

const Navbar = () => {
  const { history, location } = useRouter()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Wrapper className="hide-btn-print">
      <Container>
        <TabList>
          <TabItem isActive={location.pathname === '/'} role="button" to="/">
            หน้าแรก
          </TabItem>
          <TabItem isActive={location.pathname === '/search'} role="button" to="/search">
            ค้นหาใบจำนำ
          </TabItem>
          <TabItem isActive={location.pathname === '/pawn'} role="button" to="/pawn">
            จำนำ
          </TabItem>
          <TabItem isActive={location.pathname === '/search-user'} role="button" to="/search-user">
            ค้นหาลูกค้า
          </TabItem>
          <TabItem isActive={location.pathname === '/new-user'} role="button" to="/new-user">
            ลูกค้าใหม่
          </TabItem>
          <IconWrapper onClick={handleClick} aria-haspopup="true">
            <AccountCircleIcon fontSize="large" />
          </IconWrapper>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Paper>
              <List>
                <ListItem button onClick={() => history.push('/setting')}>
                  <ListItemText primary="ตั้งค่าระบบ" />
                </ListItem>
                <ListItem button onClick={() => history.push('/pawn-undo')}>
                  <ListItemText primary="กู้คืนใบจำนำ" />
                </ListItem>
                <ListItem
                  button
                  onClick={() =>
                    firebase
                      .auth()
                      .signOut()
                      .then(() => {
                        handleClose()
                      })
                  }
                >
                  <ListItemText primary="ออกจากระบบ" />
                </ListItem>
              </List>
            </Paper>
          </Popover>
        </TabList>
      </Container>
    </Wrapper>
  )
}

export default Navbar
