import { FetchPawnResponse, pawnService } from '@api/pawn-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import Loader from '@components/Loader'
import { Container } from '@components/styled/Container'
import { Clickable } from '@components/styled/UI'
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import { formatDate, formatNumber } from '@utils/format'
import * as React from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Wrapper } from './Search.styled'

const headCells = [
  { id: 'id', label: 'เลขที่ใบจำนำ' },
  { id: 'firstName', label: 'ชื่อ' },
  { id: 'lastName', label: 'นามสกุล' },
  { id: 'goldTypes', label: 'ประเภทของ' },
  { id: 'priceAmount', label: 'จำนวนเงิน' },
  { id: 'updatedDate', label: 'วันเวลาที่ทำรายการ' },
  { id: 'action', label: '' },
]

const Search = () => {
  const [query, setQuery] = React.useState('')
  const searchKeyword = queryClient.getQueryData<string>('searchKeyWord')
  const { data, isLoading } = useQuery<FetchPawnResponse[]>(
    `fetchPawns-${searchKeyword}`,
    () => pawnService.fetchPawns(searchKeyword),
    {
      enabled: !!searchKeyword,
    },
  )

  const [, forceRerender] = React.useState({})
  const [filteredData, setFilteredData] = React.useState<any>([])
  const [showAllPawns, setShowAllPawns] = React.useState(false)
  const { refetch, isLoading: isLoadingAllPawns } = useQuery<FetchPawnResponse[]>(
    `fetchAllPawns`,
    () => pawnService.fetchAllPawns(),
    {
      enabled: showAllPawns,
      onSuccess: (res) => {
        setFilteredData(res)
      },
    },
  )

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    queryClient.setQueryData('searchKeyWord', query)
    forceRerender({})
  }

  React.useEffect(() => {
    if (data) {
      setFilteredData(data)
    }
  }, [data])

  const tableHead = (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell size="small" key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const tableBody = (
    <TableBody>
      {filteredData && filteredData?.length > 0 ? (
        filteredData
          // @ts-ignore
          ?.sort((a, b) => new Date(b.updatedDate) - new Date(a.updatedDate))
          ?.map((pawn) => {
            const {
              id,
              person: { firstName, lastName },
              gold: { goldTypes, priceAmount },
              updatedDate,
            } = pawn
            return (
              <TableRow key={`unit-${id}`}>
                <TableCell size="small">{id}</TableCell>
                <TableCell size="small">{firstName}</TableCell>
                <TableCell size="small">{lastName}</TableCell>
                <TableCell size="small">{goldTypes.join(', ')}</TableCell>
                <TableCell size="small">{formatNumber(priceAmount)}</TableCell>
                <TableCell size="small">{formatDate(updatedDate, 'D MMM BBBB HH:mm')}</TableCell>
                <TableCell size="small" align="left">
                  <Link to={`/pawn/${id}`}>
                    <Typography color="primary">ดูใบจำนำ</Typography>
                  </Link>
                </TableCell>
              </TableRow>
            )
          })
      ) : (
        <TableRow>
          <TableCell size="small" colSpan={10}>
            ไม่พบใบจำนำ
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )

  return (
    <Container>
      <Wrapper>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h4">ค้นหาข้อมูล</Typography>
          <Clickable
            onClick={() => {
              setShowAllPawns(true)
              refetch()
            }}
          >
            <Typography color="primary">ดูข้อมูลทั้งหมด</Typography>
          </Clickable>
        </Box>
        <form onSubmit={handleSearch}>
          <Box mt={3}>
            <TextField
              variant="outlined"
              placeholder="ค้นหาหมายเลขใบจำนำ, ชื่อ, นามสกุล, รหัสบัตรประชาชน/หนังสือเดินทาง"
              fullWidth
              value={query}
              onChange={(event) => setQuery(event.target.value)}
            />
          </Box>

          <Box mt={3}>
            <Button type="submit">ค้นหา</Button>
          </Box>
        </form>
      </Wrapper>
      <Box mt={2}>
        {(searchKeyword || showAllPawns) && (
          <Paper>
            {(isLoading || isLoadingAllPawns) && (
              <Box p={3}>
                <Loader />
              </Box>
            )}
            {!isLoading && !isLoadingAllPawns && (
              <Box p={4}>
                <Typography variant="h5">
                  แสดง {filteredData?.length} {!showAllPawns ? `ใบจำนำสำหรับ ${`"${searchKeyword}"`}` : 'ใบจำนำ'}
                </Typography>
                <Box mt={2}>
                  <Table>
                    {tableHead}
                    {tableBody}
                  </Table>
                </Box>
              </Box>
            )}
          </Paper>
        )}
      </Box>
    </Container>
  )
}

export default Search
