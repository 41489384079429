import Button from '@components/Button'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography, TypographyProps } from '@material-ui/core'
import { theme } from '@styles/theme'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${theme.spacing(3)}px;
`

export const Container = styled.div`
  text-align: center;
  max-width: 625px;
`

interface ICustomTypography extends TypographyProps {
  fontSize?: number
}

export const Header = styled(Typography)<ICustomTypography>(
  ({ fontSize }) => css`
    font-size: ${fontSize}px;
    font-weight: ${theme.typography.fontWeightBold};
    margin-bottom: ${theme.spacing(1)}px;
    color: ${theme.palette.grey[700]};
  `,
)

export const SubHeader = styled(Typography)<ICustomTypography>(
  ({ fontSize }) => css`
    font-size: ${fontSize}px;
    margin-bottom: ${theme.spacing(1)}px;
    color: ${theme.palette.text.secondary};
  `,
)

export const Description = styled(Typography)`
  color: ${theme.palette.text.secondary};
`

export const ActionButton = styled(Button)`
  margin-top: ${theme.spacing(4)}px;
`
