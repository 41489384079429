import _styled from '@emotion/styled'
import { createTheme } from '@material-ui/core'
import * as React from 'react'

export interface ICustomTheme {
  custom: {
    button: {
      minWidth: React.CSSProperties['minWidth']
    }
    colors: {
      text2: React.CSSProperties['color']
    }
  }
}

const customPalette = {
  primary: {
    main: '#C04747',
  },
  secondary: {
    main: '#af3d3d',
  },
  success: {
    main: '#52B478',
  },
  info: {
    main: '#1D75BC',
  },
  warning: {
    main: '#F49A33',
  },
  error: {
    light: '#FCE8E8',
    main: '#D1375B',
  },
}

const customTheme = {
  button: {
    minWidth: 120,
  },
  colors: {
    text2: '#777777',
  },
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Kanit, sans-serif',
  },
  palette: customPalette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 540,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$disabled': {
          borderColor: '#FAFAFA',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.87)',
          backgroundColor: '#FAFAFA',
        },
      },
    },
    MuiTableRow: {
      root: {
        height: '53px',
      },
    },
    MuiFormControlLabel: {
      label: {
        marginLeft: 8,
      },
    },
  },
  custom: customTheme,
})

theme.typography.h2 = {
  ...theme.typography.h2,
  fontWeight: 400,
}

export const styled = _styled
