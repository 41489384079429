import axiosInstance from './instance'

export type CreatePawnRequest = {
  person: {
    firstName: string
    lastName: string
    idCard?: string
    phone?: string
  }
  gold: {
    goldTypes: string[]
    goldCustomType?: string
    goldWeight: number
    priceAmount: number
    targetMonth: number
    additionalInfo?: string[]
  }
}

const createPawn = (payload: CreatePawnRequest) => axiosInstance.post('/createPawn', payload).then(({ data }) => data)

export type UpdatePawnRequest = {
  id: string
  person?: {
    firstName?: string
    lastName?: string
    idCard?: string
    phone?: string
  }
  gold?: {
    goldTypes?: string[]
    goldCustomType?: string
    goldWeight?: number
    priceAmount?: number
    additionalInfo?: string[]
  }
  targetMonth?: number
  targetDate?: string
  deleted?: boolean
  updatedDate?: string
}

const updatePawn = ({ id, ...payload }: UpdatePawnRequest) =>
  axiosInstance.post(`/updatePawn?id=${id}`, payload).then(({ data }) => data)

export type FetchPawnRequest = {
  id: string | number
}

export type FetchPawnResponse = {
  id: string
  createdDate: string
  updatedDate: string
  targetMonth: number
  targetDate: string
  deleted: boolean
  person: {
    firstName: string
    lastName: string
    idCard?: string
    phone?: string
  }
  gold: {
    goldTypes: string[]
    goldCustomType?: string
    goldWeight: number
    priceAmount: number
    targetMonth: number
    additionalInfo?: string[]
  }
  user: {
    id: string
    firstName: string
    lastName: string
    idCard?: string
    phone?: string
  }
}

const fetchPawn = (params: FetchPawnRequest) => axiosInstance.get('/getPawn', { params }).then(({ data }) => data)

const fetchPawns = (query?: string) =>
  axiosInstance.get('/getPawns', { params: query ? { query } : undefined }).then(({ data }) => data)

const fetchPDFUrl = (params: { id: string }) =>
  axiosInstance.get('/generatePDFTemplate', { params }).then(({ data }) => data)

const deletePawn = (params: { id: string }) => axiosInstance.delete(`/deletePawn?id=${params.id}`)

const clearOldPawns = () => axiosInstance.delete(`/clearOldPawns`)

const fetchAllPawns = (query?: string) =>
  axiosInstance.get('/getAllPawns', { params: { query } }).then(({ data }) => data)

const fetchAllRemovedPawns = (query?: string) =>
  axiosInstance.get('/getAllRemovedPawns', { params: { query } }).then(({ data }) => data)

const undoPawn = (params: { id: string }) => axiosInstance.post(`/undoPawn?id=${params.id}`).then(({ data }) => data)

export const pawnService = {
  createPawn,
  clearOldPawns,
  updatePawn,
  deletePawn,
  fetchPawn,
  fetchPawns,
  fetchPDFUrl,
  fetchAllRemovedPawns,
  fetchAllPawns,
  undoPawn,
}
