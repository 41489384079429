import { transactionService, TransactionType } from '@api/transaction-service'
import Button from '@components/Button'
import { GridContainer } from '@components/container'
import Error from '@components/Error'
import Loader from '@components/Loader'
import Select, { IOption } from '@components/Select/Select'
import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import { theme } from '@styles/theme'
import { formatDate, formatNumber } from '@utils/format'
import dayjs from 'dayjs'
import * as React from 'react'
import { Line } from 'react-chartjs-2'
import { useQuery } from 'react-query'
import XLSX from 'xlsx'

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

const SummaryData = ({ selectedYear, selectedMonth }) => {
  const { data, error, refetch } = useQuery<any>(
    [`fetchSummaryData-${selectedMonth}-${selectedYear}`],
    () => transactionService.getSummary({ y: selectedYear, m: selectedMonth === 'all' ? undefined : selectedMonth }),
    {
      suspense: true,
    },
  )
  const [summaries, setSummaries] = React.useState<{
    pawnBalance: number
    userCount: number
    collectPoint: number
    goldWeight: number
    amount: number
  }>({ pawnBalance: 0, userCount: 0, collectPoint: 0, goldWeight: 0, amount: 0 })
  const [value, setValue] = React.useState(0)

  const transactionTypeLabels: Record<TransactionType, string> = {
    [TransactionType.PAWN]: 'จำนำ',
    [TransactionType.EXTEND]: 'ต่อดอก',
    [TransactionType.ADJUST]: 'เพิ่ม/ลดยอด',
    [TransactionType.TAKE_OUT]: 'ลูกค้าไถ่คืน',
    [TransactionType.REMOVE]: 'คัดของออก',
    [TransactionType.PURCHASE]: 'ลูกค้าซื้อทอง',
    [TransactionType.SELL]: 'เพื่มแต้มคะแนนให้ลูกค้า',
    [TransactionType.CHANGE]: 'ลูกค้าเปลี่ยนทอง',
    [TransactionType.DEPOSIT]: 'ลูกค้าออมเงิน',
    [TransactionType.WITHDRAW]: 'ลูกค้าถอนเงิน',
    [TransactionType.UNDO]: 'กู้คืนใบจำนำ',
  }

  const excelData = [
    [`ปี ${+selectedYear + 543}`, `เดือน ${selectedMonth !== 'all' ? dayjs(selectedMonth).format('MMM') : 'ทั้งหมด'}`],
    ['ยอดจำนำ (บาท)', 'จำนวนลูกค้า (คน)', 'ยอดแต้มสะสม', 'น้ำหนักทอง (กรัม)'],
    [summaries.pawnBalance, summaries.userCount, summaries.collectPoint, summaries.goldWeight],
    [],
    [
      'รายการ',
      'จำนวนเงิน',
      'ดอกเบี้ย',
      'น้ำหนัก',
      'แต้มสะสม',
      'วันที่ทำรายการ',
      'ชื่อ',
      'นามสกุล',
      'รหัสบัตรประชาชน/หนังสือเดินทาง',
      'เบอร์โทรติดต่อ',
    ],
    ...data
      // @ts-ignore
      ?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
      .map((dt) => [
        transactionTypeLabels[dt.type],
        dt?.change?.price || 0,
        dt?.change?.interest || 0,
        dt?.change?.weight || 0,
        dt?.change?.point || 0,
        formatDate(dt?.createdDate, 'D MMM BBBB HH:mm'),
        dt?.info?.firstName || dt?.info?.user?.firstName,
        dt?.info?.lastName || dt?.info?.user?.lastName,
        dt?.info?.idCard || dt?.info?.user?.idCard,
        dt?.info?.phone || dt?.info?.user?.phone,
      ]),
  ]

  const exportToExcel = (data: any[][]) => {
    const worksheet = XLSX.utils.aoa_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ภาพรวม')
    XLSX.writeFile(workbook, 'สรุปรายการ.xlsx')
  }

  const getSummaryCalculation = (data: any) => {
    let _pawnBalance = 0
    const _userHash = {}
    let _collectPoint = 0
    let _weight = 0
    let _amount = 0

    data.forEach((dt) => {
      if (
        [
          TransactionType.PAWN,
          TransactionType.EXTEND,
          TransactionType.ADJUST,
          TransactionType.REMOVE,
          TransactionType.TAKE_OUT,
          TransactionType.UNDO,
        ].includes(dt.type)
      ) {
        if (dt.change.price !== undefined && dt.change.price) {
          _pawnBalance = _pawnBalance + dt.change.price
        }
      }

      if (
        [
          TransactionType.PAWN,
          TransactionType.EXTEND,
          TransactionType.ADJUST,
          TransactionType.REMOVE,
          TransactionType.PURCHASE,
          TransactionType.SELL,
          TransactionType.CHANGE,
          TransactionType.DEPOSIT,
          TransactionType.WITHDRAW,
          TransactionType.TAKE_OUT,
          TransactionType.UNDO,
        ].includes(dt.type)
      ) {
        const userId = dt.info?.user?.id || dt.info?.id
        if (userId) {
          _userHash[userId] = userId
        }

        _collectPoint = _collectPoint + dt.change.point

        if (!dt.info?.gold?.additionalInfo?.includes('ทอง 90')) {
          _weight = _weight + dt.change.weight
        }
      }

      if ([TransactionType.DEPOSIT, TransactionType.WITHDRAW].includes(dt.type)) {
        if (dt.change.price !== undefined && dt.change.price) {
          _amount = _amount + dt.change.price
        }
      }

      // Transaction type { dt.type }
      // PAWN = 'pawn',
      // EXTEND = 'extend',
      // ADJUST = 'adjust',
      // TAKE_OUT = 'take-out',
      // REMOVE = 'remove',
      // PURCHASE = 'purchase',
      // SELL = 'sell',
      // CHANGE = 'change',
      // DEPOSIT = 'deposit',
      // WITHDRAW = 'withdraw',
      // UNDO = 'undo',
    })

    const userCount = Object.keys(_userHash).length

    return {
      pawnBalance: _pawnBalance,
      userCount: userCount,
      collectPoint: _collectPoint,
      goldWeight: _weight,
      amount: _amount,
    }
  }

  React.useEffect(() => {
    if (data) {
      let currentDate = dayjs().set('year', selectedYear)

      if (selectedMonth !== 'all') {
        currentDate = currentDate.set('month', dayjs(selectedMonth).get('month'))
      }

      setSummaries(getSummaryCalculation(data.filter((dt) => dayjs(dt.createdDate) <= currentDate)))
    }
  }, [data, selectedMonth, selectedYear])

  const sum = (list, key) => {
    const summary = getSummaryCalculation(list)

    switch (key) {
      case 'userCount':
        return summary['userCount']
      case 'collectPoint':
        return summary['collectPoint']
      case 'goldWeight':
        return summary['goldWeight']
      case 'amount':
        return summary['amount']
      case 'pawnBalance':
      default:
        return summary['pawnBalance']
    }
  }

  const indexKeys = {
    0: 'pawnBalance',
    1: 'userCount',
    2: 'collectPoint',
    3: 'goldWeight',
    4: 'amount',
  }

  const lengthData = selectedMonth === 'all' ? 12 : dayjs(selectedMonth).daysInMonth()

  const transformedData = Array.from({ length: lengthData }).map((_, i) => {
    let currentDate = dayjs().set('year', selectedYear)

    if (selectedMonth === 'all') {
      currentDate = currentDate.set('month', i)
    } else {
      currentDate = currentDate.set('month', dayjs(selectedMonth).get('month'))
      currentDate = currentDate.set('date', i + 1)
    }

    return dayjs() >= currentDate
      ? sum(
          data.filter((dt) => dayjs(dt.createdDate) <= currentDate),
          indexKeys[value],
        )
      : null
  })

  const chartData = {
    labels: Array.from({ length: lengthData }).map((_, i) => i + 1),
    datasets: [
      {
        label: '#',
        data: transformedData,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: theme.palette.primary.main,
      },
    ],
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Box mt={2}>
      <Box mb={2} display="flex" justifyContent="end">
        <Button
          variant="outlined"
          onClick={() => {
            exportToExcel(excelData)
          }}
        >
          ดาวน์โหลด
        </Button>
      </Box>
      <GridContainer columns={5}>
        <Paper>
          <Box p={3}>
            <Typography variant="h6" color="textSecondary">
              ยอดจำนำ (บาท)
            </Typography>
            <Box pt={4}>
              <Typography variant="h4">{formatNumber(summaries.pawnBalance)}</Typography>
            </Box>
          </Box>
        </Paper>
        <Paper>
          <Box p={3}>
            <Typography variant="h6" color="textSecondary">
              จำนวนลูกค้า (คน)
            </Typography>
            <Box pt={4}>
              <Typography variant="h4">{formatNumber(summaries.userCount)}</Typography>
            </Box>
          </Box>
        </Paper>
        <Paper>
          <Box p={3}>
            <Typography variant="h6" color="textSecondary">
              ยอดแต้มสะสม
            </Typography>
            <Box pt={4}>
              <Typography variant="h4">{formatNumber(summaries.collectPoint)}</Typography>
            </Box>
          </Box>
        </Paper>
        <Paper>
          <Box p={3}>
            <Typography variant="h6" color="textSecondary">
              น้ำหนักทอง (กรัม)
            </Typography>
            <Box pt={4}>
              <Typography variant="h4">{formatNumber(summaries.goldWeight)}</Typography>
            </Box>
          </Box>
        </Paper>
        <Paper>
          <Box p={3}>
            <Typography variant="h6" color="textSecondary">
              ยอดออมทอง
            </Typography>
            <Box pt={4}>
              <Typography variant="h4">{formatNumber(summaries.amount)}</Typography>
            </Box>
          </Box>
        </Paper>
      </GridContainer>
      <Box mt={2}>
        <Paper>
          <Box p={2}>
            <Tabs value={value} onChange={(_, value) => setValue(value)} aria-label="tabs">
              <Tab label="ยอดจำนำ" />
              <Tab label="จำนวนลูกค้า (คน)" />
              <Tab label="ยอดแต้มสะสม" />
              <Tab label="น้ำหนักทอง (กรัม)" />
              <Tab label="ยอดออมทอง" />
            </Tabs>
            {/* @ts-ignore */}
            <Line data={chartData} options={options} />
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

const Summary = () => {
  const { data, error, refetch } = useQuery<any>(`fetchSummaryFilter`, () => transactionService.getSummaryFilter(), {
    suspense: true,
  })
  const [selectedYear, setSelectedYear] = React.useState<string | undefined>()
  const [selectedMonth, setSelectedMonth] = React.useState<string | undefined>()
  const [yearOptions, setYearOptions] = React.useState<IOption[]>([])
  const [monthOptions, setMonthOptions] = React.useState<IOption[]>([])

  React.useEffect(() => {
    if (data) {
      const monthDefaultOption: any = [{ label: 'ทั้งหมด', value: 'all' }]
      const mOption = [
        ...monthDefaultOption,
        ...Object.keys(data.months)
          .filter((month) => month.split('-')[0] === selectedYear)
          .sort((a: any, b: any) => b.split('-')[1] - a.split('-')[1])
          .map((month) => ({ label: `${dayjs(month).format('MMM')}`, value: month })),
      ]

      setMonthOptions(mOption)
      setSelectedMonth(`${mOption[0].value}`)
    }
  }, [data, selectedYear])

  React.useEffect(() => {
    if (data) {
      const yOption = Object.keys(data.years)
        .map((year) => ({ label: `${+year + 543}`, value: year }))
        // @ts-ignore
        .sort((a, b) => b.value - a.value)

      setYearOptions(
        // @ts-ignore
        yOption.length > 0 ? yOption : [{ label: `${dayjs().year() + 543}`, value: dayjs().year() }],
      )
      setSelectedYear(`${yOption[0]?.value || dayjs().year()}`)
    }
  }, [data])

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Box mb={3}>
      <Box display="flex" justifyContent="flex-end">
        <Box pr={2}>
          <Select
            label="เดือน"
            value={selectedMonth || ''}
            options={monthOptions}
            onChange={(value) => setSelectedMonth(value)}
            size="small"
          />
        </Box>
        <Select
          label="ปี"
          value={selectedYear || ''}
          options={yearOptions}
          onChange={(value) => setSelectedYear(value)}
          size="small"
        />
      </Box>
      <React.Suspense fallback={<Loader />}>
        <SummaryData selectedMonth={selectedMonth} selectedYear={selectedYear} />
      </React.Suspense>
    </Box>
  )
}

export default Summary
