import { getIn } from 'formik'

export const makeFieldProps = ({ values, errors, touched, onBlur, onChange }: any) => {
  const getFieldProps = (key: string): any => ({
    name: key,
    value: getIn(values, key),
    helperText: getIn(touched, key) && getIn(errors, key),
    error: !!getIn(errors, key) && getIn(touched, key),
    onBlur,
    onChange,
  })

  return {
    getFieldProps,
  }
}
