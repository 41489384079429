import { User, userService } from '@api/user-service'
import Button from '@components/Button'
import Loader from '@components/Loader'
import { Box, TextField, Typography } from '@material-ui/core'
import { useRouter } from '@shared/hooks/useRouter'
import { makeFieldProps } from '@utils/form'
import { useFormik } from 'formik'
import * as React from 'react'
import { useQuery } from 'react-query'
import * as Yup from 'yup'
import { InformationWrapper, ReadFromIdButton } from './PIForm.styled'

type FormSchema = {
  idCard: string
  firstName: string
  lastName: string
  phone: string
}

const schema = Yup.object().shape({
  firstName: Yup.string().max(150).required('กรุณากรอกชื่อ'),
  lastName: Yup.string().max(150).required('กรุณากรอกนามสกุล'),
  idCard: Yup.number().typeError('กรุณากรอกเฉพาะตัวเลข'),
  phone: Yup.number().typeError('กรุณากรอกเฉพาะตัวเลข'),
})

type PIFormProps = {
  onSubmit?: (values: FormSchema) => void
  initialValues: FormSchema
  submitText?: string
  title?: string
}

const PIForm: React.FC<PIFormProps> = ({ onSubmit, initialValues, submitText = 'ถัดไป', title = 'จำนำ' }) => {
  const { query } = useRouter()
  const { data: user, isLoading } = useQuery<User>(
    `fetchUser-${query.userId}`,
    () => userService.fetchUser({ id: query?.userId as string }),
    {
      enabled: !!query?.userId,
    },
  )
  const { handleChange, handleBlur, values, handleSubmit, errors, touched, setValues } = useFormik<FormSchema>({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      onSubmit?.(values)
    },
  })
  const [isWaitingFromIdCard, setIsWaitingFormIdCard] = React.useState(false)

  const { getFieldProps } = makeFieldProps({ values, errors, touched, onBlur: handleBlur, onChange: handleChange })

  const handleReadFromIdCard = () => {
    setIsWaitingFormIdCard(true)
    userService.deleteSessionUserIdentity()
  }

  useQuery<User[]>(`fetchUserSession`, () => userService.getSessionUserIdentity(), {
    enabled: isWaitingFromIdCard,
    onSuccess: (values: any) => {
      setValues({
        ...values,
      })
      setIsWaitingFormIdCard(false)
      userService.deleteSessionUserIdentity()
    },
    refetchInterval: 3000,
  })

  React.useEffect(() => {
    if (user) {
      setValues({
        firstName: user.firstName,
        lastName: user.lastName,
        idCard: user.idCard || '',
        phone: user.phone || '',
      })
    }
  }, [setValues, user])

  if (isWaitingFromIdCard || isLoading) {
    return (
      <Box display="flex" flexDirection="column">
        <Loader />
        {isWaitingFromIdCard && (
          <Box mt={2} mx="auto">
            <Button onClick={() => setIsWaitingFormIdCard(false)}>ยกเลิก</Button>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Box mb={3}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Box mb={3}>
        <InformationWrapper>
          <Typography variant="h5">ข้อมูลผู้จำนำ</Typography>
          <ReadFromIdButton type="button" onClick={handleReadFromIdCard}>
            <Typography color="primary">อ่านจากบัตรประชาชน</Typography>
          </ReadFromIdButton>
        </InformationWrapper>
      </Box>
      <Box mb={3}>
        <TextField label="ชื่อ" variant="outlined" fullWidth {...getFieldProps('firstName')} />
      </Box>
      <Box mb={3}>
        <TextField label="นามสกุล" variant="outlined" fullWidth {...getFieldProps('lastName')} />
      </Box>
      <Box mb={3}>
        <TextField label="รหัสบัตรประชาชน/หนังสือเดินทาง" variant="outlined" fullWidth {...getFieldProps('idCard')} />
      </Box>
      <Box mb={5}>
        <TextField
          label="เบอร์โทร"
          placeholder="เบอร์โทร (ไม่จำเป็น)"
          variant="outlined"
          fullWidth
          {...getFieldProps('phone')}
        />
      </Box>

      <Button type="submit">{submitText}</Button>
    </form>
  )
}

export default PIForm
