import { transactionService, TransactionType } from '@api/transaction-service'
import { User, userService } from '@api/user-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import { GridContainer } from '@components/container'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { useConfirm } from '@components/Modal/ConfirmModal'
import { useNotification } from '@components/notification'
import { CommonCard } from '@components/styled/CommonCard'
import { Container } from '@components/styled/Container'
import { Box, TextField, Typography } from '@material-ui/core'
import { useRouter } from '@shared/hooks/useRouter'
import { formatNumber } from '@utils/format'
import * as React from 'react'
import { useMutation, useQuery } from 'react-query'

const Deposit = () => {
  const { confirm } = useConfirm()
  const { pushDefaultError, pushNotification } = useNotification()
  const { query, history } = useRouter()
  const { data: user, error, isLoading, refetch } = useQuery<User>(`fetchUser-${query.id}`, () =>
    userService.fetchUser({ id: query?.id as string }),
  )
  const { mutate: depositGold, isLoading: isUpdating } = useMutation(userService.addAmount, {
    mutationKey: 'depositGold',
    onSuccess: async () => {
      queryClient.invalidateQueries()

      await transactionService.createTransaction({
        info: user,
        type: TransactionType.DEPOSIT,
        change: {
          point: 0,
          interest: 0,
          price: amount,
          weight: 0,
        },
      })

      pushNotification('บันทึกข้อมูลสำเร็จ')

      history.push(`/user/${query.id}`)
    },
    onError: () => {
      pushDefaultError()
    },
  })
  const [amount, setAmount] = React.useState(0)

  const handleDeposit = () => {
    if (user) {
      // @ts-ignore
      if (amount < 0) {
        pushNotification('ไม่สามารถฝากเงินติดลบได้')
      } else {
        confirm(() =>
          depositGold({
            id: user.id,
            amount,
          }),
        )
      }
    }
  }

  if (isLoading) {
    return (
      <Container>
        <CommonCard>
          <Loader />
        </CommonCard>
      </Container>
    )
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Container>
      <CommonCard>
        <Typography variant="h5">ออมทอง (ฝากเงิน)</Typography>
        <Box mt={3}>
          <Box mt={3}>
            <TextField
              label="จำนวนเงิน (บาท)"
              fullWidth
              variant="outlined"
              name="amount"
              value={amount.toString()}
              type="number"
              onChange={({ target: { value } }) => setAmount(+value)}
            />
          </Box>
          <Box mt={3}>
            <GridContainer columns={2} maxColumns={3}>
              <Typography>จำนวนเงินที่มีอยู่:</Typography>
              <Box ml={3}>
                <Typography>{formatNumber(user?.amount)} บาท</Typography>
              </Box>
            </GridContainer>
          </Box>
          <Box mt={4}>
            <Button onClick={handleDeposit} isLoading={isUpdating}>
              บันทึก
            </Button>
          </Box>
        </Box>
      </CommonCard>
    </Container>
  )
}

export default Deposit
