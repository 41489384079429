import { CreatePawnRequest, pawnService } from '@api/pawn-service'
import { transactionService, TransactionType } from '@api/transaction-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import { useConfirm } from '@components/Modal/ConfirmModal'
import { useNotification } from '@components/notification'
import Select from '@components/Select'
import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core'
import { additionalInfos, goldTypes } from '@shared/constants/gold'
import dayjs from '@utils/dayjs'
import { makeFieldProps } from '@utils/form'
import { useFormik } from 'formik'
import * as React from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { CheckboxContainer, GridContainer } from './/ItemForm.styled'

export type FormSchema = {
  goldTypes: string[]
  goldCustomType: string
  goldWeight: string
  priceAmount: string
  additionalInfo: string[]
  targetMonth: number
}

type ItemFormProps = {
  values: {
    firstName: string
    lastName: string
    idCard?: string
    phone?: string
  }
  onSubmit: (values: FormSchema) => void
  onBack: () => void
}

const schema = Yup.object().shape({
  goldTypes: Yup.array(),
  goldCustomType: Yup.string(),
  goldWeight: Yup.number().typeError('กรุณากรอกเฉพาะตัวเลข').required('กรุณากรอกน้ำหนักทอง (กรัม)'),
  priceAmount: Yup.number().typeError('กรุณากรอกเฉพาะตัวเลข').required('กรุณากรอกจำนวนเงิน (บาท)'),
  additionalInfo: Yup.array(),
  targetMonth: Yup.number(),
})

const ItemForm: React.FC<ItemFormProps> = ({ values: personValues, onSubmit, onBack }) => {
  const { confirm } = useConfirm()
  const history = useHistory()
  const { pushDefaultError } = useNotification()
  const { handleChange, handleBlur, values, handleSubmit, errors, touched, setFieldValue } = useFormik<FormSchema>({
    initialValues: {
      goldTypes: [],
      goldCustomType: '',
      goldWeight: '',
      priceAmount: '',
      additionalInfo: [],
      targetMonth: 2,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      confirm(() => handleCreatePawn(values))
    },
  })
  const { mutate: createPawn, isLoading } = useMutation(
    (values: CreatePawnRequest) =>
      pawnService.createPawn({
        ...values,
        gold: {
          ...values.gold,
          goldTypes: [
            ...values.gold.goldTypes.filter((type) => type !== 'อื่น ๆ'),
            ...(values.gold.goldCustomType ? [values.gold.goldCustomType] : []),
          ],
        },
      }),
    {
      mutationKey: 'createPawn',
      onSuccess: ({ id, ...values }) => {
        transactionService.createTransaction({
          info: {
            id,
            ...values,
          },
          type: TransactionType.PAWN,
          change: {
            point: 0,
            interest: 0,
            price: values.gold.priceAmount,
            weight: values.gold.goldWeight,
          },
        })
        history.replace(`${history.location.pathname}?id=${id}`)
        onSubmit?.(values)
        queryClient.invalidateQueries()
      },
      onError: () => {
        pushDefaultError()
      },
    },
  )

  const handleCreatePawn = (formValues: FormSchema) => {
    createPawn({
      person: {
        firstName: personValues.firstName,
        lastName: personValues.lastName,
        idCard: personValues.idCard,
        phone: personValues.phone,
      },
      gold: {
        goldTypes: formValues.goldTypes,
        goldCustomType: formValues.goldCustomType,
        goldWeight: +formValues.goldWeight,
        priceAmount: +formValues.priceAmount,
        targetMonth: formValues.targetMonth,
        additionalInfo: formValues.additionalInfo,
      },
    })
  }

  const { getFieldProps } = makeFieldProps({ values, errors, touched, onBlur: handleBlur, onChange: handleChange })

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Box mb={4}>
        <Typography variant="h4">จำนำ</Typography>
      </Box>
      <Box mb={4}>
        <Box mb={3}>
          <Typography variant="h5">ข้อมูลผู้จำนำ</Typography>
        </Box>
        <GridContainer>
          <Typography>ชื่อ: </Typography>
          <Typography>{personValues.firstName}</Typography>
          <Typography>นามสกุล: </Typography>
          <Typography>{personValues.lastName}</Typography>
          <Typography>รหัสบัตรประชาชน/หนังสือเดินทาง: </Typography>
          <Typography>{personValues.idCard || '-'}</Typography>
          <Typography>เบอร์โทร: </Typography>
          <Typography>{personValues.phone || '-'}</Typography>
        </GridContainer>
      </Box>
      <Box mb={4}>
        <Box mb={3}>
          <Typography variant="h5">ข้อมูลของ</Typography>
        </Box>
        <Typography>ประเภททอง</Typography>

        <Box pl={2} pt={3}>
          <CheckboxContainer>
            {goldTypes.map(({ name, label }) => (
              <FormControlLabel
                key={name}
                control={
                  <Checkbox
                    name="goldTypes"
                    color="primary"
                    onChange={(event) => {
                      if (event.target.checked) {
                        const newGoldTypes = [...values.goldTypes, label]
                        setFieldValue('goldTypes', newGoldTypes)
                      } else {
                        const newGoldTypes = values.goldTypes.filter((type) => type !== label)
                        setFieldValue('goldTypes', newGoldTypes)
                      }
                    }}
                    checked={values.goldTypes.includes(label)}
                  />
                }
                label={label}
              />
            ))}
          </CheckboxContainer>
        </Box>
        {values.goldTypes.includes('อื่น ๆ') && (
          <Box mt={3}>
            <TextField variant="outlined" label="ระบุประเภททอง" fullWidth {...getFieldProps('goldCustomType')} />
          </Box>
        )}
        <Box mt={3}>
          <TextField variant="outlined" label="น้ำหนักทอง (กรัม)" fullWidth {...getFieldProps('goldWeight')} />
        </Box>
        <Box mt={3}>
          <TextField variant="outlined" label="จำนวนเงิน (บาท)" fullWidth {...getFieldProps('priceAmount')} />
        </Box>
        <Box pt={3}>
          <Typography>ข้อมูลเพิ่มเติม</Typography>
        </Box>
        <Box pl={2} pt={3}>
          <CheckboxContainer>
            {additionalInfos.map(({ name, label }) => (
              <FormControlLabel
                key={name}
                control={
                  <Checkbox
                    name="additionalInfo"
                    color="primary"
                    onChange={(event) => {
                      if (event.target.checked) {
                        const newInfos = [...values.additionalInfo, label]
                        setFieldValue('additionalInfo', newInfos)
                      } else {
                        const newInfos = values.additionalInfo.filter((type) => type !== label)
                        setFieldValue('additionalInfo', newInfos)
                      }
                    }}
                  />
                }
                label={label}
              />
            ))}
          </CheckboxContainer>
        </Box>
      </Box>
      <Typography variant="h5">วันครบกำหนด</Typography>
      <Box pt={3}>
        <Select
          label="ระยะเวลาครบกำหนด"
          value={values.targetMonth}
          options={Array.from({ length: 12 }).map((_, index) => ({ label: `${index + 1} เดือน`, value: index + 1 }))}
          onChange={(value) => setFieldValue('targetMonth', value)}
          fullWidth
        />
      </Box>
      <Box pt={3}>
        <GridContainer>
          <Typography>วันครบกำหนด:</Typography>
          <Typography>{dayjs().add(values.targetMonth, 'months').format('D MMM BBBB')}</Typography>
        </GridContainer>
      </Box>
      <Box mt={4} display="flex">
        <Button type="submit" isLoading={isLoading}>
          เพิ่มข้อมูล
        </Button>
        <Box ml={1}>
          <Button type="button" variant="outlined" onClick={onBack} disabled={isLoading}>
            ย้อนกลับ
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default ItemForm
