import { transactionService, TransactionType } from '@api/transaction-service'
import { User, userService } from '@api/user-service'
import { queryClient } from '@app/query-client'
import Button from '@components/Button'
import { GridContainer } from '@components/container'
import Error from '@components/Error'
import Loader from '@components/Loader'
import { useConfirm } from '@components/Modal/ConfirmModal'
import { useNotification } from '@components/notification'
import { CommonCard } from '@components/styled/CommonCard'
import { Container } from '@components/styled/Container'
import { Box, TextField, Typography } from '@material-ui/core'
import { useRouter } from '@shared/hooks/useRouter'
import { formatDate, formatNumber } from '@utils/format'
import * as React from 'react'
import { useMutation, useQuery } from 'react-query'

const GoldPurchase = () => {
  const { confirm } = useConfirm()
  const { pushDefaultError, pushNotification } = useNotification()
  const { query, history } = useRouter()
  const { data: user, error, isLoading, refetch } = useQuery<User>(`fetchUser-${query.id}`, () =>
    userService.fetchUser({ id: query?.id as string }),
  )
  const { mutate: withdrawGold } = useMutation(userService.removeAmount, {
    mutationKey: 'withdrawGold',
    onSuccess: async () => {
      queryClient.invalidateQueries(`fetchUser-${query.id}`)

      transactionService.createTransaction({
        info: user,
        type: TransactionType.WITHDRAW,
        change: {
          point: 0,
          interest: 0,
          price: -amount,
          weight: 0,
        },
      })

      pushNotification('บันทึกข้อมูลสำเร็จ')

      history.push(`/user/${query.id}`)
    },
    onError: () => {
      pushDefaultError()
    },
  })
  const { mutate: purchaseGold, isLoading: isUpdating } = useMutation(userService.removePoint, {
    mutationKey: 'purchaseGold',
    onSuccess: async () => {
      queryClient.invalidateQueries(`fetchUser-${query.id}`)

      transactionService.createTransaction({
        info: user,
        type: query?.type === 'change' ? TransactionType.CHANGE : TransactionType.PURCHASE,
        change: {
          point: -point,
          interest: 0,
          price: 0,
          weight: 0,
        },
      })

      withdrawGold({
        id: query.id,
        amount,
      })
    },
    onError: () => {
      pushDefaultError()
    },
  })
  const [point, setPoint] = React.useState(0)
  const [amount, setAmount] = React.useState(0)
  const [sellPrice, setSellPrice] = React.useState(0)
  const [currentDate, setCurrentDate] = React.useState(new Date())

  React.useEffect(() => {
    const handleUpdateCurrentDate = () => {
      setCurrentDate(new Date())
    }

    const timer = setInterval(handleUpdateCurrentDate, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const handlePurchase = () => {
    if (user) {
      // @ts-ignore
      if (point < 0 || point > user.point) {
        pushNotification('ไม่สามารถใช้คะแนนสะสมที่มากกว่าคะแนนที่มีอยู่ได้')
        // @ts-ignore
      } else if (amount < 0 || amount > user.amount) {
        pushNotification('ไม่สามารถใช้เงินออมมากกว่าที่มีอยู่ได้')
      } else {
        confirm(() => {
          purchaseGold({
            id: user.id,
            point,
          })
        })
      }
    }
  }

  if (isLoading) {
    return (
      <Container>
        <CommonCard>
          <Loader />
        </CommonCard>
      </Container>
    )
  }

  if (error) {
    return <Error onRetry={refetch} />
  }

  return (
    <Container>
      <CommonCard>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography variant="h5">{query?.type === 'change' ? 'ลูกค้าเปลี่ยนทอง' : 'ลูกค้าซื้อทอง'}</Typography>
          <Box display="flex" justifyContent="flex-end">
            <Typography>{formatDate(currentDate.toISOString(), 'D MMM BBBB HH:mm')}</Typography>
          </Box>
        </Box>
        <Typography>{`${user?.firstName} ${user?.lastName}`}</Typography>
        <Box mt={3}>
          <GridContainer columns={2} maxColumns={3}>
            <Typography>คะแนนสะสมที่ใช้ได้</Typography>
            <Box ml={3}>
              <Typography>{formatNumber(user?.point)}</Typography>
            </Box>
            <Typography>จำนวนเงินออมที่มีอยู่:</Typography>
            <Box ml={3}>
              <Typography>{formatNumber(user?.amount)} บาท</Typography>
            </Box>
          </GridContainer>
        </Box>
        <Box mt={3}>
          <Box mt={3}>
            <TextField
              label="ราคาขาย"
              fullWidth
              variant="outlined"
              name="sellPrice"
              value={sellPrice.toString()}
              type="number"
              onChange={({ target: { value } }) => setSellPrice(+value)}
            />
            <Box mt={3} />

            <TextField
              label="คะแนนที่ต้องการใช้"
              fullWidth
              variant="outlined"
              name="point"
              value={point.toString()}
              type="number"
              onChange={({ target: { value } }) => setPoint(+value)}
              // @ts-ignore
              error={point > user.point}
              // @ts-ignore
              helperText={point > user?.point && `ไม่สามารถใช้คะแนนสะสมมากกว่าที่มีอยู่`}
            />
            <Box mt={3} />

            <TextField
              label="จำนวนเงินออมที่ต้องการใช้"
              fullWidth
              variant="outlined"
              name="amount"
              value={amount.toString()}
              type="number"
              onChange={({ target: { value } }) => setAmount(+value)}
              // @ts-ignore
              error={amount > user.amount}
              // @ts-ignore
              helperText={amount > user?.amount && `ไม่สามารถใช้เงินออมมากกว่าที่มีอยู่`}
            />
          </Box>
          <Box mt={3}>
            <GridContainer columns={2} maxColumns={3}>
              <Typography>ราคาสุทธิ</Typography>
              <Box ml={3}>
                <Typography>
                  {formatNumber(sellPrice - point - amount <= 0 ? 0 : sellPrice - point - amount)} บาท
                </Typography>
              </Box>
            </GridContainer>
          </Box>
          <Box mt={1}>
            <Typography color="textSecondary">* ขอรับรองว่าเป็นทองแท้ 96.5 %</Typography>
          </Box>

          <Box mt={4} display="flex">
            <Button onClick={handlePurchase} isLoading={isUpdating} className="hide-btn-print">
              บันทึก
            </Button>
            <Box ml={2}>
              <Button
                onClick={() => {
                  window.print()
                }}
                variant="outlined"
                className="hide-btn-print"
              >
                พิมพ์
              </Button>
            </Box>
          </Box>
        </Box>
      </CommonCard>
    </Container>
  )
}

export default GoldPurchase
