import axiosInstance from '@api/instance'

export enum TransactionType {
  PAWN = 'pawn',
  EXTEND = 'extend',
  ADJUST = 'adjust',
  TAKE_OUT = 'take-out',
  REMOVE = 'remove',
  PURCHASE = 'purchase',
  SELL = 'sell',
  CHANGE = 'change',
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  UNDO = 'undo',
}

export type TransactionRequest = {
  change: {
    point: number
    price: number
    weight: number
    interest: number
  }
  type: TransactionType
  info: any
}

const createTransaction = ({ change, type, info }: TransactionRequest) =>
  axiosInstance.post(`/createTransaction`, { change, type, info }).then(({ data }) => data)

const getUserTransactions = ({ id }: { id: string }) =>
  axiosInstance.get(`/getUserTransactions?id=${id}`).then(({ data }) => data)

const getSummaryFilter = () => axiosInstance.get('/getSummaryFilter').then(({ data }) => data)

const getSummary = (params: { y: string; m?: string }) =>
  axiosInstance.get('/getSummary', { params }).then(({ data }) => data)

export const transactionService = {
  createTransaction,
  getUserTransactions,
  getSummaryFilter,
  getSummary,
}
