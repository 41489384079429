import { css } from '@emotion/react'

export const globalStyle = css`
  body {
    margin: 0;
    min-height: 100vh;
    font-family: 'Kanit', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    background: #fafafa;
    min-width: 768px;
  }

  a {
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }
`
