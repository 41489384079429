import axiosInstance from './instance'

export type SettingPayload = {
  interestRate: number
  rateConverter: number
}

const fetchSetting = () => axiosInstance.get('/getSetting').then(({ data }) => data)

const updateSetting = (payload: SettingPayload) =>
  axiosInstance.post('/updateSetting', payload).then(({ data }) => data)

export const settingService = {
  fetchSetting,
  updateSetting,
}
