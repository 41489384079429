import * as React from 'react'
import { Wrapper } from './GridContainer.styled'

export interface IGridProps {
  columns?: number
  rows?: number
  columnGap?: number
  rowGap?: number
  maxColumns?: number
  columnSpan?: number
  rowSpan?: number
  className?: string
}

const GridContainer: React.FC<IGridProps> = ({
  columns = 1,
  rows = 1,
  columnGap = 2,
  rowGap = 2,
  columnSpan = 1,
  rowSpan = 1,
  maxColumns,
  children,
  className,
}) => (
  <Wrapper
    columns={columns}
    rows={rows}
    columnGap={columnGap}
    rowGap={rowGap}
    maxColumns={maxColumns}
    columnSpan={columnSpan}
    rowSpan={rowSpan}
    className={className}
  >
    {children}
  </Wrapper>
)

export default GridContainer
