import { Snackbar } from '@material-ui/core'
import React, { useContext } from 'react'

type ContextType = {
  pushNotification: (message: string) => void
  pushDefaultError: (message?: string) => void
}

export const NotificationContext = React.createContext({} as ContextType)

export const useNotification = () => {
  return useContext(NotificationContext)
}

export const NotificationProvider: React.FC = ({ children }) => {
  const [errorMessage, setErrorMessage] = React.useState<string>()

  const pushNotification = (message: string) => {
    setErrorMessage(message)
  }

  const pushDefaultError = (message = 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง') => {
    setErrorMessage(message)
  }

  const value = {
    pushNotification,
    pushDefaultError,
  }

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
    </NotificationContext.Provider>
  )
}
