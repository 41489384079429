import { css } from '@emotion/react'
import { theme } from '@styles/theme'
import styled from '@emotion/styled'

export interface IWrapperProps {
  columns: number
  rows: number
  columnGap: number
  rowGap: number
  maxColumns?: number
  columnSpan: number
  rowSpan: number
}

export const Wrapper = styled.div<IWrapperProps>`
  display: grid;
  position: relative;
  align-items: start;
  grid-column-gap: ${(props) => theme.spacing(props.columnGap)}px;
  grid-row-gap: ${(props) => theme.spacing(props.rowGap)}px;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-template-rows: repeat(${(props) => props.rows}, 1fr);
  grid-column: span ${(props) => props.columnSpan};
  grid-row: span ${(props) => props.rowSpan};

  ${(props) =>
    props.maxColumns &&
    css`
      width: calc(
        (
          100% * ${props.columns} / ${props.maxColumns} -
            ${(theme.spacing(props.columnGap) / props.maxColumns) * (props.maxColumns - props.columns)}px
        )
      );
    `};
`
