import { transactionService, TransactionType } from '@api/transaction-service'
import Button from '@components/Button'
import { Box, Dialog, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { Clickable } from '@pages/UserDetail/UserDetail.styled'
import { theme } from '@styles/theme'
import { formatDate, formatNumber } from '@utils/format'
import * as React from 'react'
import { useQuery } from 'react-query'

const headCells = [
  { id: 'type', label: 'ประเภท' },
  { id: 'amount', label: 'จำนวนเงิน' },
  { id: 'createdDate', label: 'วันที่ทำรายการ' },
]

const DepositHistoryModal = ({ open, onClose, id, name }) => {
  const { data } = useQuery(['user-transactions'], () => transactionService.getUserTransactions({ id }), {
    cacheTime: 1,
  })

  const tableHead = (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell size="small" key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const typeLabels: { [k in TransactionType]?: string } = {
    [TransactionType.DEPOSIT]: 'ฝากเงิน',
    [TransactionType.WITHDRAW]: 'ถอนเงิน',
  }

  const tableBody = (
    <TableBody>
      {data &&
      data?.filter(({ type }) => [TransactionType.DEPOSIT, TransactionType.WITHDRAW].includes(type))?.length > 0 ? (
        data
          ?.filter(({ type }) => [TransactionType.DEPOSIT, TransactionType.WITHDRAW].includes(type))
          // @ts-ignore
          ?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
          .map((transaction) => {
            const {
              id,
              type,
              createdDate,
              change: { price },
            } = transaction
            return (
              <TableRow key={`transactions-${id}`}>
                <TableCell size="small">
                  <span
                    style={{
                      color: type === TransactionType.DEPOSIT ? theme.palette.success.main : theme.palette.primary.main,
                    }}
                  >
                    {typeLabels[type]}
                  </span>
                </TableCell>
                <TableCell size="small">{formatNumber(price)} บาท</TableCell>
                <TableCell size="small">{formatDate(createdDate, 'D MMM BBBB HH:mm')}</TableCell>
              </TableRow>
            )
          })
      ) : (
        <TableRow>
          <TableCell size="small" colSpan={10}>
            ไม่มีประวัติการออม
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )

  const sum = data
    ?.filter(({ type }) => [TransactionType.DEPOSIT, TransactionType.WITHDRAW].includes(type))
    // @ts-ignore
    ?.reduce((result, i) => (result = result + i?.change?.price), 0)

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose} fullWidth>
      <Box p={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">ประวัติการออม</Typography>
          <Box display="flex" justifyContent="flex-end">
            <Box flexWrap="no-wrap" mr={2}>
              <Button
                onClick={() => {
                  window.print()
                }}
                className="hide-btn-print"
              >
                พิมพ์รายการ
              </Button>
            </Box>
            <Clickable onClick={onClose}>
              <Typography className="hide-btn-print" color="primary">
                ปิด
              </Typography>
            </Clickable>
          </Box>
        </Box>
        <Typography>({name})</Typography>
        <Box mt={3}>
          <Typography>ยอดรวม: {sum} บาท</Typography>
        </Box>

        <Box mt={3}>
          {tableHead}
          {tableBody}
        </Box>
      </Box>
    </Dialog>
  )
}

export default DepositHistoryModal
