import styled from '@emotion/styled'

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)}px;
`

export const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
