import { ButtonProps, CircularProgress } from '@material-ui/core'
import * as React from 'react'
import { LoaderWrapper, MuiButton } from './Button.styled'

export interface IButtonProps extends ButtonProps {
  isLoading?: boolean
}

const LOADER_SIZE = 24

const Button: React.FC<IButtonProps> = ({
  isLoading = false,
  disabled = false,
  color = 'primary',
  variant = 'contained',
  children,
  ...props
}) => {
  const loader = (
    <LoaderWrapper>
      <CircularProgress size={LOADER_SIZE} />
    </LoaderWrapper>
  )

  return (
    <MuiButton size="large" variant={variant} color={color} disabled={disabled || isLoading} {...props}>
      {isLoading && loader}
      {children}
    </MuiButton>
  )
}

export default Button
