import { pawnService } from '@api/pawn-service'
import { useNotification } from '@components/notification'
import firebase from '@utils/firebase'
import { useQuery } from 'react-query'

export const usePrintPawn = (id: string) => {
  const { pushDefaultError } = useNotification()

  const { data: pdfData, isLoading, refetch } = useQuery<{ path: string }>(
    `fetchPDFUrl-${id}`,
    () => pawnService.fetchPDFUrl({ id }),
    {
      cacheTime: 0,
      enabled: false,
      retry: false,
      onSuccess: async (data) => {
        if (data) {
          const storageRef = firebase.storage().ref()
          const pdfRef = storageRef.child(data.path)

          try {
            const url = await pdfRef.getDownloadURL()
            window.open(url, '_blank')
          } catch (_) {
            pushDefaultError()
          }
        }
      },
      onError: () => {
        pushDefaultError()
      },
    },
  )

  return {
    print: refetch,
    data: pdfData,
    isLoading,
  }
}
