import Button from '@components/Button'
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

type ContextType = {
  confirm: (cb: any) => any
}

export const ConfirmModal = React.createContext({} as ContextType)

export const useConfirm = () => {
  return useContext(ConfirmModal)
}

let resolveCallback
export const ConfirmModalProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  // to use confirm(handleSomething) or confirm(() => cb())
  const confirm = (cb) => {
    setIsOpen(true)

    new Promise((resolve) => {
      resolveCallback = resolve
    }).then((isConfirmed) => {
      if (isConfirmed && typeof cb === 'function') {
        cb?.()
      }
    })
  }

  const handleConfirm = () => {
    setIsOpen(false)
    resolveCallback(true)
  }

  const handleCancel = () => {
    setIsOpen(false)
    resolveCallback(false)
  }

  const value = {
    confirm,
  }

  return (
    <ConfirmModal.Provider value={value}>
      <ErrorBoundary
        fallbackRender={({ resetErrorBoundary }) => (
          <Box p={5}>
            <Typography>มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง</Typography>
            <Box mt={2}>
              <Button onClick={() => resetErrorBoundary()}>ลองใหม่อีกครั้ง</Button>
            </Box>
          </Box>
        )}
      >
        {children}
      </ErrorBoundary>
      <Dialog open={isOpen} maxWidth="md">
        <DialogTitle>
          <Box mt={1}>ต้องการดำเนินการต่อหรือไม่ ?</Box>
        </DialogTitle>
        <DialogContent>
          การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง คุณต้องการดำเนินการหรือไม่ ?
          <Box my={3}>
            <Box display="flex">
              <Button onClick={handleConfirm}>ยืนยัน</Button>
              <Box ml={2}>
                <Button variant="outlined" onClick={handleCancel}>
                  ยกเลิก
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </ConfirmModal.Provider>
  )
}
