import { Person, userService } from '@api/user-service'
import { queryClient } from '@app/query-client'
import { useConfirm } from '@components/Modal/ConfirmModal'
import { useNotification } from '@components/notification'
import { CommonCard } from '@components/styled/CommonCard'
import { Container } from '@components/styled/Container'
import PIForm from '@pages/Pawn/components/PIForm'
import { useRouter } from '@shared/hooks/useRouter'
import React from 'react'
import { useMutation } from 'react-query'

const initialValues = {
  idCard: '',
  firstName: '',
  lastName: '',
  phone: '',
}

const NewUser = () => {
  const { history } = useRouter()
  const { confirm } = useConfirm()
  const { pushDefaultError } = useNotification()
  const { mutate: createUser } = useMutation((payload: Person) => userService.createUser(payload), {
    onSuccess: async (values) => {
      await queryClient.invalidateQueries()
      history.push(`/user/${values.id}`)
    },
    onError: () => {
      pushDefaultError('เกิดข้อผิดพลาดหรือมีผู้ใช้งานอยู่ในระบบ กรุณาลองใหม่อีกครั้ง')
    },
  })

  return (
    <Container>
      <CommonCard>
        <PIForm
          initialValues={initialValues}
          title="ลูกค้าใหม่"
          submitText="สร้าง"
          onSubmit={(values) => confirm(() => createUser(values))}
        />
      </CommonCard>
    </Container>
  )
}

export default NewUser
