import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div`
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
`

export const TabList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
  display: flex;

  > li:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(6)}px;
  }
`

export const TabItem = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>`
  padding: ${({ theme }) => theme.spacing(2, 0)};
  margin: ${({ theme }) => theme.spacing(0, 6, 0, 0)};
  height: 100%;
  width: max-content;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.custom.colors.text2};
  font-weight: 500;

  &:visited {
    color: ${({ theme }) => theme.custom.colors.text2};
  }

  ${({ theme }) => css`
    &:hover {
      color: ${theme.palette.primary.main};
      &:after {
        display: block;
        content: '';
        height: 2px;
        width: 100%;
        background: ${theme.palette.primary.main};
        border-radius: 2px;
        position: absolute;
        bottom: 0;
      }
    }
  `}

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.palette.primary.main} !important;
      &:after {
        display: block;
        content: '';
        height: 2px;
        width: 100%;
        background: ${theme.palette.primary.main};
        border-radius: 2px;
        position: absolute;
        bottom: 0;
      }
    `};
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  color: #aaa;
  cursor: pointer;
`
