import { TextField, TextFieldProps } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import * as React from 'react'

export interface IOption {
  label?: string
  value?: any
}

interface ISelectProps extends Omit<TextFieldProps, 'value' | 'onChange' | 'variant'> {
  value?: any
  onChange?: any
  options?: IOption[]
}

const Select: React.FC<ISelectProps> = ({ name, label, onChange, value, options = [], ...props }) => {
  const handleChange = (event) => {
    const newValue = event.target.value

    return onChange?.(newValue)
  }

  return (
    <TextField variant="outlined" name={name} label={label} onChange={handleChange} value={value} select {...props}>
      {options?.map((item, index) => (
        <MenuItem key={`menu-${name}-${item.label}-${index}`} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default Select
