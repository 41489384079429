import * as React from 'react'
import { ActionButton, Container, Description, SubHeader, Wrapper } from './Error.styled'

interface IErrorProps {
  onRetry: () => void
  size?: keyof typeof errorSizes
}

const errorSizes = {
  small: { logo: { width: 375, height: 225 }, header: 36, subHeader: 18 },
  medium: { logo: { width: 500, height: 300 }, header: 48, subHeader: 24 },
  large: { logo: { width: 625, height: 375 }, header: 60, subHeader: 30 },
}

const Error: React.FC<IErrorProps> = ({ onRetry, size = 'small' }) => (
  <Wrapper>
    <Container>
      <SubHeader fontSize={errorSizes[size].subHeader}>This page failed to load</SubHeader>
      <Description variant="body2">There was a problem. Please check your connection and try again</Description>
      <ActionButton onClick={onRetry}>Try again</ActionButton>
    </Container>
  </Wrapper>
)

export default Error
