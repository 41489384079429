import styled from '@emotion/styled'
import { Button } from '@material-ui/core'

export const MuiButton = styled(Button)`
  min-width: ${({
    theme: {
      custom: {
        button: { minWidth },
      },
    },
  }) => minWidth}px;
`

export const LoaderWrapper = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
